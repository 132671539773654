import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import { toast } from 'react-toastify';
import { all } from 'axios';
import { useSelector } from 'react-redux';
import DatePickerDash from 'components/DatePickerDash';
import TagModal from 'pages/settings/tags/TagModal';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import TaxModal from 'pages/settings/taxes/TaxModal';
import useCustomerSearch from 'hooks/useCustomerSearch';
import useBasicDropdownSearch from 'hooks/useBasicDropdownSearch';
import {
  VIEWER,
  panelAmountColor,
  removeDuplicates,
  statusColor,
  lightenColor,
  Transfer,
  getContrast,
  STAFF,
  transformAccounts,
  trimText,
  getCurrencySymbolFromIso,
  getFormattedDate,
  formatDateLocally,
  TEAM_DATE_FORMAT,
  TEAM_CURRENCY,
  areArraysEqual,
  formatNumberGlobally,
} from '../../utils/Utils';
import {
  getSingleMerchant,
  getSingleTransaction,
  updateTransaction,
  updateTransactionTags,
  updateTransactionCategory,
  getAccountById,
  createMerchant,
  getAccountTypes,
  getAccountSubTypes,
  getAccounts,
  reviewTransaction,
  uploadMerchantLogo,
  unassignTransactionToBill,
  getPeople,
  getCustomers,
  getCustomerById,
  createTag,
  getTags,
  createTax,
  deleteTransactionsByIDs,
} from '../../API/backend_helper';
import PaymentMethod from '../component/custom/PaymentMethod';
import Tooltip from '../../components/Tooltip';

import useClickOutside from '../../utils/useClickOutside';
import CategoriesDropdown from './CategoriesDropdown';
import MerchantsDropdown from '../../components/MerchantsDropdown';
import DropdownFull from '../../components/DropdownFull';
import SplitTransactionModal from './SplitTransactionModal';

import ReceiptPanel from './ReceiptPanel';
import CommentsPanel from './CommentsPanel';
import BasicCheckboxDropdown from '../component/BasicCheckboxDropdown';
import { Loader } from '../../components/Svg';
import AddTaskBtn from './AddTaskBtn';
import AddCustomerModal from '../invoices/AddCustomerModal';
import ModalBasic from '../../components/ModalBasic';
import VendorForm from '../vendors/VendorForm';
import AccountsModal from '../accounts/AccountsModal';
import DeleteTransactionModal from './DeleteTransactionModal';

const DoubleTickIcon = (className = '') => (
  <svg
    width="16"
    height="10"
    viewBox="0 0 16 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7.85524 6.6217C7.99464 6.6217 8.13334 6.56385 8.23219 6.4503L12.5356 1.51815C12.7173 1.31015 12.6958 0.994197 12.4875 0.812847C12.2802 0.631447 11.9643 0.652197 11.7822 0.860947L7.47879 5.7931C7.29714 6.0011 7.31864 6.31705 7.52689 6.4984C7.62159 6.58115 7.73854 6.6217 7.85524 6.6217Z"
      fill="#78BD4F"
    />
    <path
      d="M15.8126 0.378983C15.6051 0.197833 15.2891 0.218833 15.1073 0.427083L8.07111 8.49078L4.67021 4.71513C4.48491 4.50983 4.16901 4.49323 3.96391 4.67828C3.75881 4.86308 3.74221 5.17928 3.92706 5.38458L5.91151 7.58768L5.12531 8.49008L1.72516 4.71568C1.53986 4.51038 1.22396 4.49378 1.01886 4.67883C0.813758 4.86388 0.797158 5.17983 0.982008 5.38513L4.76011 9.57873C4.85506 9.68393 4.99011 9.74403 5.13171 9.74403H5.13586C5.27891 9.74283 5.41466 9.68033 5.50866 9.57238L6.58571 8.33613L7.70546 9.57923C7.80041 9.68443 7.93546 9.74453 8.07706 9.74453H8.08096C8.22401 9.74333 8.35976 9.68103 8.45376 9.57313L15.8608 1.08438C16.0423 0.876283 16.0208 0.560633 15.8126 0.378983Z"
      fill="#78BD4F"
    />
  </svg>
);

let noteTimeout = null;
let tagsTimeout = null;
let taxesTimeout = null;

function TransactionDetails({
  transactionPanelOpen,
  setTransactionPanelOpen,
  transaction,
  reloadTransaction,
  setTransaction,
  setModalOpen,
  modalOpen,
  tags,
  setTags,
  allCategories,
  setAllCategories,
  vendors,
  setVendors,
  setSplitTransactionModalOpen,
  handleSplitModalSelectInstance,
  setReceiptModalOpen,
  receiptModalOpen,
  activeReceipt,
  setActiveReceipt,
  currentTab,
  setCurrentTab,
  accounts,
  fromDropdown = false,
  setFromDropdown,
  // findSplitParent,
  allExpenseCategories,
  setStaffModal,
  staffModal,
  localUser,
  customers,
  setCustomers,
  reconcile = false,
  journalEntry = false,
  ruleModalOpen,
  setRuleModalOpen,
  setPopupOpen,
  setPopupCategory,
  setPopupTransaction,
  handlePopupLogic,
  taskModalOpen,
  setTaskModalOpen,
  setSelectedTaskOption,
  currencies,
  invoices,
  assignTransactionToInvoice,
  removeTransactionFromInvoice,
  audit = false,
  allInvoices,
  setAllExpenseCategories,
  allBills,
  bills,
  assignTransactionToBillApi,
  removeTransactionFromBill,
  projects,
  projectPage = false,
  loadTransactionFromApi = false,
  taxes = [],
  loadTaxes = async () => {},
  allModalAccounts = [],
  setAllModalAccounts = () => {},
}) {
  const { accessType, permissions } = useSelector((state) => state.User);
  const { team } = useSelector((state) => state.Team);

  const teamDateFormat =
    team?.defaults?.dateFormat || localStorage.getItem(TEAM_DATE_FORMAT);

  const teamDefaultCurrency =
    team?.currency || localStorage.getItem(TEAM_CURRENCY);

  const closeBtn = useRef(null);
  const panelContent = useRef(null);
  const tagTrigger = useRef(null);
  const tagDropdown = useRef(null);
  const flatPickrRef = useRef(null);
  const [note, setNote] = useState('');
  const [savingNotes, setSavingNotes] = useState(false);
  const [hidden, setHidden] = useState(transaction?.excluded);
  const [customerProjectLoader, setCustomerProjectLoader] = useState(false);
  const [localTransaction, setLocalTransaction] = useState(transaction);
  const [isSplitting, setIsSplitting] = useState(false);
  const [reviewed, setReviewed] = useState(transaction?.reviewed);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [localTags, setLocalTags] = useState([]);
  const [vendorId, setVendorId] = useState(transaction?.vendorId);
  const [customerId, setCustomerId] = useState(transaction?.customerId);
  const [isSplittedTransactionSelect, setIsSplittedTransactionSelect] =
    useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [localTransactionAmount, setLocalTransactionAmount] = useState(
    transaction?.amount?.toString().charAt(0) === '-'
      ? transaction?.amount
      : transaction?.amount * -1,
  );
  const [localName, setLocalName] = useState(transaction?.description);
  const [localDate, setLocalDate] = useState(
    formatDateLocally(
      transaction?.authorizedDate || new Date(),
      teamDateFormat,
    ),
  );
  const [initialTransactionSign, setInitialTransactionSign] = useState(
    transaction?.amount?.toString().charAt(0) === '-' ? '-' : '',
  );
  const [accountDropdownVisible, setAccountDropdownVisible] = useState(false);
  const [tagDropdownOpen, setTagDropdownOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState(
    transaction?.tags ? transaction?.tags?.map((tag) => tag.id) : [],
  );
  const [reviewedLoading, setReviewedLoading] = useState(false);
  const [localSplits, setLocalSplits] = useState([]);
  const [displayVendor, setDisplayVendor] = useState(false);
  const [displayCustomer, setDisplayCustomer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [transactionLoading, setTransactionLoading] = useState(false);
  const [taskDropdownOpen, setTaskDropdownOpen] = useState(false);
  const [customerModalOpen, setCustomerModalOpen] = useState(false);
  const [vendorModalOpen, setVendorModalOpen] = useState(false);
  const [categoryModalOpen, setCategoryModalOpen] = useState(false);
  const [accountTypes, setAccountTypes] = useState([]);
  const [accountSubTypes, setAccountSubTypes] = useState([]);
  const [accountModalOpen, setAccountModalOpen] = useState(false);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [billLoading, setBillLoading] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [projectLoading, setProjectLoading] = useState(false);
  const [salesRep, setSalesRep] = useState([]);
  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [taxesLoading, setTaxesLoading] = useState(false);
  const [accountsLoading, setAccountsLoading] = useState(false);
  const [selectedTaxes, setSelectedTaxes] = useState(
    transaction?.taxes ? transaction?.taxes?.map((tax) => tax.id) : [],
  );
  const [taxModalOpen, setTaxModalOpen] = useState(false);
  const [isSplitsLoading, setIsSplitsLoading] = useState(false);
  const [deleteTransactionModal, setDeleteTransactionModal] = useState('');
  const [deleteModalLoading, setDeleteModalLoading] = useState(false);
  const [deleteModalParent, setDeleteModalParent] = useState(null);

  const {
    dropdownCustomers,
    handleCustomerSearch,
    resetCustomerSearch,
    searchCustomerLoading,
    loadCustomerById,
    setDropdownCustomers,
  } = useCustomerSearch(customers, setCustomers);

  const {
    dropdownOptions: dropdownProjects,
    handleDropdownSearch: handleProjectsSearch,
    resetDropdownSearch: resetProjectsSearch,
  } = useBasicDropdownSearch({ options: projects });

  const disabledTaxes = useMemo(() => {
    if (localTransaction?.taxes?.length > 0) {
      const disabled = localTransaction?.taxes?.filter((tax) => !tax?.enabled);
      return disabled || [];
    }
    return [];
  }, [localTransaction]);

  const options = useMemo(
    () => ({
      mode: 'single',
      static: true,
      monthSelectorType: 'static',
      allowInput: true,
      dateFormat: teamDateFormat === 'dd/mm/yyyy' ? 'd/m/Y' : 'm/d/Y',
      // maxDate: new Date(),
      prevArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
      nextArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
      onOpen(selectedDates, dateStr, instance) {
        if (
          localTransaction?.splitParentId ||
          localTransaction?.reviewed ||
          localTransaction?.pending
        ) {
          instance.close();
        }
      },
      disableMobile: true,
    }),
    [team, transaction],
  );

  const getSalesRepApi = async () => {
    try {
      const res = await getPeople({
        limit: 1000000,
        roles: ['sales rep'],
      });
      setSalesRep(res?.people);
    } catch (e) {
      console.log('error', e);
    }
  };

  useEffect(() => {
    getSalesRepApi();
  }, []);

  useEffect(() => {
    if (localTransaction?.customerId) {
      loadCustomerById(localTransaction?.customerId);
    }
  }, [localTransaction?.customerId]);

  useEffect(() => {
    if (transaction) {
      getAccountSubTypesApi();
      getAccountTypesApi();
      setHidden(transaction?.excluded);
      // setLocalTransaction(transaction);
      setVendorId(transaction?.vendorId);
      setLocalTransactionAmount(
        transaction?.amount?.toString().charAt(0) === '-'
          ? transaction?.amount
          : transaction?.amount * -1,
      );
      setLocalName(transaction?.description);
      setLocalDate(
        formatDateLocally(
          transaction?.authorizedDate || new Date(),
          teamDateFormat,
        ),
      );
      setInitialTransactionSign(
        transaction?.amount?.toString().charAt(0) === '-' ? '-' : '',
      );
      setSelectedTags(
        transaction?.tags ? transaction?.tags?.map((tag) => tag.id) : [],
      );
      setSelectedTaxes(
        transaction?.taxes ? transaction?.taxes?.map((tax) => tax.id) : [],
      );
      if (transaction?.customerId || transaction?.customer?.id) {
        setDisplayCustomer(true);
      }
      if (transaction?.vendorId || transaction?.vendor?.id) {
        setDisplayVendor(true);
      }
    }
  }, [transaction]);

  useEffect(() => {
    const getCategoryById = async () => {
      setIsLoading(true);
      try {
        const catRes = await getAccountById(
          localTransaction?.categoryAccountId,
        );
        const accountRes = await getAccountById(localTransaction?.accountId);
        if (catRes && accountRes) {
          setLocalTransaction((prev) => ({
            ...prev,
            categoryAccount: catRes,
            account: accountRes,
          }));
          setSelectedCategory(catRes?.id);
        }
      } catch (error) {
        console.log('error', error);
      }
      setIsLoading(false);
    };
    if (
      journalEntry &&
      !localTransaction?.categoryAccount?.id &&
      !localTransaction?.account?.id &&
      localTransaction?.accountId &&
      localTransaction?.categoryAccountId
    ) {
      getCategoryById();
    }
  }, [localTransaction, journalEntry]);

  useEffect(() => {
    if (!transactionPanelOpen) {
      setTransactionPanelOpen(false);
      setVendorId(null);
      setTransaction(null);
      setCurrentTab('details');
      setIsEditing(false);
      setIsSplitting(false);
      setFromDropdown(false);
      setLocalSplits([]);
    }
  }, [transactionPanelOpen]);

  useEffect(() => {
    const fpInput = flatPickrRef.current?.input;
    const preventOpen = (event) => {
      if (
        localTransaction?.splitParentId ||
        localTransaction?.reviewed ||
        localTransaction?.pending
      ) {
        event.preventDefault();
        event.target.blur();
      }
    };

    if (fpInput) {
      fpInput.addEventListener('focus', preventOpen);
    }

    return () => {
      if (fpInput) {
        fpInput.removeEventListener('focus', preventOpen);
      }
    };
  }, [localTransaction]);

  useClickOutside(tagDropdown, () => {
    if (tagDropdownOpen) {
      setTagDropdownOpen(false);
    }
  });

  useEffect(() => {
    if (
      localTransaction?.vendorId &&
      vendors?.length > 0 &&
      !vendors?.find((m) => m?.id === localTransaction?.vendorId)?.id
    ) {
      getMerchant();
    }
  }, [localTransaction?.vendorId, vendors]);

  useEffect(() => {
    if (localTransaction?.id && !journalEntry) {
      // setLocalTransaction(localTransaction);
      setSelectedCategory(localTransaction?.categoryAccountId);
      setSelectedTags(
        localTransaction?.tags
          ? localTransaction?.tags?.map((tag) => tag.id)
          : [],
      );
      setSelectedTaxes(
        localTransaction?.taxes
          ? localTransaction?.taxes?.map((tax) => tax.id)
          : [],
      );
      setSelectedProject(localTransaction?.projectId);
      if (
        localTransaction?.projectId &&
        localTransaction?.project?.customerId &&
        localTransaction?.project?.customer
      ) {
        setDisplayCustomer(true);
        setCustomerId(localTransaction?.project?.customer);
      }
      // loadTransaction(!!localTransaction?.split);
      initialTransactionSign &&
        setLocalTransactionAmount(localTransaction?.amount * -1);
    }
    if (fromDropdown) {
      setIsEditing(true);
      localTransaction?.split && setIsSplitting(true);
    }
  }, [localTransaction]);

  // useEffect(() => {
  //   if (!audit) {
  //     const transactionTags = transaction?.tags?.map((tag) => tag.id);
  //     if (JSON.stringify(selectedTags) !== JSON.stringify(transactionTags)) {
  //       tagsTimeout = setTimeout(() => {
  //         updateTags(selectedTags);
  //       }, 900);
  //     }
  //   }
  // }, [selectedTags]);

  useEffect(() => {
    setNote(localTransaction?.notes || '');
  }, [localTransaction?.notes]);

  useEffect(() => {
    if (localTransaction?.splits) {
      let splitArray = [];
      if (localTransaction?.splitParent?.id) {
        const adjustedParent = {
          ...localTransaction.splitParent,
          originalAmount: localTransaction.splitParent.originalAmount * -1,

          amount: localTransaction.splitParent.amount * -1,
        };
        splitArray = [adjustedParent];
      } else {
        const adjustedAmount = localTransaction?.amount * -1;
        const adjustedOriginalAmount =
          localTransaction?.splits[0]?.originalAmount * -1;

        splitArray.push({
          id: localTransaction?.splits?.[0]?.id,
          amount: adjustedAmount,
          categoryAccountId: localTransaction?.categoryAccountId,
          categoryAccount: localTransaction?.categoryAccount,
          originalAmount: adjustedOriginalAmount,
        });
      }

      const adjustedSplits = localTransaction?.splits.map((split) => ({
        ...split,
        amount: split.amount * -1,
      }));

      splitArray.push(...adjustedSplits);

      setLocalSplits(splitArray);
    }
  }, [localTransaction?.splits]);

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!transactionPanelOpen || keyCode !== 27) return;
      setTransactionPanelOpen(false);
      setTransaction(null);
      setIsEditing(false);
      setIsSplitting(false);
      setFromDropdown(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    const loadSplitsTransaction = async () => {
      if (transaction?.id && (transaction?.split || loadTransactionFromApi)) {
        // && transaction?.splitParentId === null
        if (transaction?.split) {
          setIsSplitsLoading(true);
        }
        await loadTransaction(loadTransactionFromApi);
        if (transaction?.split) {
          setIsSplitsLoading(false);
        }
      }
    };
    loadSplitsTransaction();
  }, [transaction]);

  useEffect(() => {
    if (audit && transaction) {
      loadTransaction(true);
    }
  }, [transaction]);

  useClickOutside(panelContent, () => {
    if (
      !receiptModalOpen &&
      !staffModal &&
      !taskModalOpen &&
      !customerModalOpen &&
      !vendorModalOpen &&
      !ruleModalOpen &&
      !accountModalOpen &&
      !tagModalOpen &&
      !taxModalOpen &&
      !deleteTransactionModal
    ) {
      setTransactionPanelOpen(false);
      setVendorId(null);
      setTransaction(null);
      setCurrentTab('details');
      setIsEditing(false);
      setIsSplitting(false);
      setFromDropdown(false);
      setLocalSplits([]);
    }
  });

  const onHidden = async (bool) => {
    setHidden(bool);
    try {
      const response = await updateTransaction(localTransaction?.id, {
        excluded: bool,
      });
      setLocalTransaction(response);
      await reloadTransaction({ scrollToTop: false });
    } catch (e) {
      setHidden(!bool);
      toast.error(
        'An error occurred while saving. Please refresh and try again.',
      );
    }
  };

  const onReviewed = async (bool) => {
    setReviewed(bool);
    setReviewedLoading(true);
    try {
      const response = await reviewTransaction(localTransaction?.id);
      setReviewed(response?.data?.result?.reviewed);
      loadTransaction();
      await reloadTransaction({ scrollToTop: false });
      toast.success(
        bool
          ? 'Transaction reviewed successfully.'
          : 'Transaction set to unreviewed.',
      );
    } catch (e) {
      setReviewed(!bool);
      toast.error(
        'An error occurred while saving. Please refresh and try again.',
      );
    }
    setReviewedLoading(false);
  };

  const handleTagSelect = (selectedTag) => {
    setSelectedTags((prevTags) => {
      const isTagSelected = prevTags.some((tag) => tag === selectedTag);

      let newTags;
      if (isTagSelected) {
        newTags = prevTags.filter((tag) => tag !== selectedTag);
      } else {
        newTags = [...prevTags, selectedTag];
      }
      clearTimeout(tagsTimeout);
      tagsTimeout = null;
      tagsTimeout = setTimeout(() => {
        updateTags(newTags);
      }, 900);

      return newTags;
    });
  };

  const handleTaxSelect = (selectedTax) => {
    setSelectedTaxes((prevTaxes) => {
      const isTagSelected = prevTaxes.some((tax) => tax === selectedTax);

      let newTaxes;
      if (isTagSelected) {
        newTaxes = prevTaxes.filter((tax) => tax !== selectedTax);
      } else {
        newTaxes = [...prevTaxes, selectedTax];
      }

      clearTimeout(taxesTimeout);
      taxesTimeout = null;
      taxesTimeout = setTimeout(async () => {
        if (
          areArraysEqual(
            newTaxes,
            localTransaction?.taxes?.map((tax) => tax.id) || [],
          )
        ) {
          return;
        }
        setTaxesLoading(true);
        const response = await updateTransaction(localTransaction?.id, {
          taxes: newTaxes,
        });
        setLocalTransaction(response);
        await reloadTransaction({ scrollToTop: false });
        setTaxesLoading(false);
      }, 1500);

      return newTaxes;
    });
  };

  const handleProjectSelect = async (project) => {
    try {
      if (project === null) {
        setProjectLoading(true);
      }
      const response = await updateTransaction(localTransaction?.id, {
        projectId: project,
      });
      if (response?.customerId) {
        setDisplayCustomer(true);
        setCustomerId(response?.customerId);
      }
      setLocalTransaction(response);
      await reloadTransaction({ scrollToTop: false });
      if (project === null) {
        setSelectedProject(null);
        setProjectLoading(false);
      }
      if (projectPage) {
        setTransactionPanelOpen(false);
      }
      setCustomerProjectLoader(false);
    } catch (e) {
      setCustomerProjectLoader(false);
      toast.error(
        'An error occurred while saving. Please refresh and try again.',
      );
    }
  };

  const updateTags = async (newTags) => {
    if (localTransaction?.id) {
      try {
        const response = await updateTransactionTags(localTransaction?.id, {
          tags: newTags,
        });
        await reloadTransaction({ scrollToTop: false });
      } catch (error) {
        toast.error('Failed to update tags, please refresh and try again.');
      }
    }
  };

  const onEditSubmit = async (amount, name, date) => {
    const updatedAmount = amount || localTransaction?.amount;
    const updatedName = name || localTransaction?.description;
    let updatedDate = date || localTransaction?.authorizedDate;

    setLocalDate(updatedDate);
    updatedDate = getFormattedDate(updatedDate, teamDateFormat);
    setLocalTransactionAmount(
      initialTransactionSign ? updatedAmount : updatedAmount,
    );
    setLocalName(updatedName);
    if (
      amount !== localTransaction?.amount ||
      name !== localTransaction?.description ||
      date !== localTransaction?.authorizedDate
    ) {
      try {
        const response = await updateTransaction(localTransaction?.id, {
          amount: updatedAmount,
          description: updatedName,
          authorizedDate: updatedDate,
        });
        setLocalTransaction(response);
        await reloadTransaction({ scrollToTop: false });
      } catch (e) {
        setLocalTransactionAmount(
          initialTransactionSign
            ? localTransaction?.amount
            : localTransaction?.amount * -1,
        );
        setLocalName(localTransaction?.description);
        setLocalDate(
          formatDateLocally(
            localTransaction?.authorizedDate || new Date(),
            teamDateFormat,
          ),
        );
        toast.error(
          'An error occurred while saving. Please refresh and try again.',
        );
      }
    }
  };

  const handleAmountChange = (e) => {
    setLocalTransactionAmount(e.target.value);
  };

  const handleNameChange = (e) => {
    setLocalName(e.target.value);
  };

  const handleDateChange = (e) => {
    setLocalDate(e);
  };

  const handleCategoryClick = (e) => {
    e.preventDefault();
    setIsSplitting(true);
  };

  const noteChange = async (value) => {
    setSavingNotes(true);
    setNote(value);
    clearTimeout(noteTimeout);
    noteTimeout = null;
    noteTimeout = setTimeout(async () => {
      // if (
      //   (value?.length > 0 || customer?.notes?.length) &&
      //   value !== customer?.notes

      // ) {
      try {
        const response = await updateTransaction(localTransaction?.id, {
          notes: value,
        });
        await reloadTransaction({ scrollToTop: false });
      } catch (e) {
        setSavingNotes(false);
        toast.error(
          'An error occurred while saving. Please refresh and try again.',
        );
      }
      // }
      setSavingNotes(false);
    }, 900);
  };

  const onChangeCategroy = async (val) => {
    setCategoryLoading(true);
    setSelectedCategory(val);
    try {
      await updateTransactionCategory(localTransaction?.id, {
        categoryAccountId: val,
      });
      const res = await getSingleTransaction(localTransaction?.id);
      setLocalTransaction(res);
      await reloadTransaction({ scrollToTop: false });
    } catch (e) {
      toast.error(
        'An error occurred while saving. Please refresh and try again.',
      );
    }
    setCategoryLoading(false);
  };

  const onChangeMerchant = async (val) => {
    setVendorId(val);
    try {
      const response = await updateTransaction(localTransaction?.id, {
        vendorId: val,
      });
      setLocalTransaction(response);
      await reloadTransaction({ scrollToTop: false });
    } catch (e) {
      toast.error(
        'An error occurred while saving. Please refresh and try again.',
      );
    }
  };

  const onChangeCustomer = async (val) => {
    try {
      setCustomerId(val);
      const response = await updateTransaction(localTransaction?.id, {
        customerId: val,
      });
      setLocalTransaction(response);
      await reloadTransaction({ scrollToTop: false });
      setCustomerProjectLoader(false);
    } catch (e) {
      setCustomerProjectLoader(false);
      toast.error(
        'An error occurred while saving. Please refresh and try again.',
      );
    }
  };

  const handleRemoveTransactionFromInvoice = async () => {
    setInvoiceLoading(true);
    try {
      await removeTransactionFromInvoice(
        localTransaction?.invoiceTransaction?.invoiceId,
        localTransaction?.id,
      );
      await loadTransaction();
    } catch (e) {
      console.log('error', e);
    }
    setInvoiceLoading(false);
  };

  const handleRemoveTransactionFromBill = async () => {
    setBillLoading(true);
    try {
      await removeTransactionFromBill(
        localTransaction?.billTransaction?.billInstance?.billId,
        localTransaction?.billTransaction?.billInstanceId,
        { transactionId: localTransaction?.id },
      );
      await loadTransaction();
    } catch (e) {
      console.log('error', e);
    }
    setBillLoading(false);
  };

  const handleAssignTransactionToInvoice = async (id, transactionId) => {
    setInvoiceLoading(true);
    setCategoryLoading(true);
    try {
      await assignTransactionToInvoice(id, transactionId);
      await loadTransaction();
    } catch (e) {
      console.log('error', e);
    }
    setInvoiceLoading(false);
    setCategoryLoading(false);
  };

  const handleAssignTransactionToBill = async (
    billId,
    instance,
    transactionId,
  ) => {
    setBillLoading(true);
    setCategoryLoading(true);
    try {
      await assignTransactionToBillApi(billId, instance, transactionId);
      await loadTransaction();
    } catch (e) {
      console.log('error', e);
    }
    setBillLoading(false);
    setCategoryLoading(false);
  };

  const closeSplitTransaction = () => {
    setIsSplitting(false);
    setIsEditing(false);
  };

  const handleAddVendor = async (data) => {
    try {
      const response = await createMerchant(data);
      setVendors((prev) => [response, ...prev]);
      onChangeMerchant(response?.id);
      return response;
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleUploadVendorLogo = async (data, id) => {
    try {
      await uploadMerchantLogo(data, id);
    } catch (err) {
      console.log('err', err);
    }
  };

  const loadTransaction = async (
    loading = loadTransactionFromApi || false,
    _id = transaction?.id,
  ) => {
    if (loading) {
      setTransactionLoading(true);
    }
    try {
      const res = await getSingleTransaction(_id);
      setLocalTransaction(res);
      if (!transaction?.excluded) {
        setHidden(res?.excluded);
      }
      if (!transaction?.reviewed) {
        setReviewed(res?.reviewed);
      }
      if (!transaction?.vendorId) {
        setVendorId(res?.vendorId);
      }
      if (!transaction?.customerId) {
        setCustomerId(res?.customerId);
      }
      if (!transaction?.description) {
        setLocalName(res?.description);
      }
      if (!transaction?.authorizedDate) {
        setLocalDate(
          formatDateLocally(res?.authorizedDate || new Date(), teamDateFormat),
        );
      }
      if (!transaction?.amount) {
        setLocalTransactionAmount(
          res?.amount?.toString().charAt(0) === '-'
            ? res?.amount
            : res?.amount * -1,
        );
      }
      if (!transaction?.description) {
        setInitialTransactionSign(
          res?.amount?.toString().charAt(0) === '-' ? '-' : '',
        );
      }
      if (!transaction?.tags) {
        setSelectedTags(res?.tags ? res?.tags?.map((tag) => tag.id) : []);
      }
      if (!transaction?.taxes) {
        setSelectedTaxes(res?.taxes ? res?.taxes?.map((tax) => tax.id) : []);
      }
      setSelectedCategory(res?.categoryAccountId);
      if (isSplitting) {
        setIsSplitting(false);
        setIsEditing(false);
      }
      if (res?.customerId) {
        setDisplayCustomer(true);
        setCustomerId(res?.customerId);
      } else if (res?.vendorId) {
        setDisplayVendor(true);
        setVendorId(res?.vendorId);
      }
      if (!res?.customerId) {
        setDisplayCustomer(false);
        setCustomerId(null);
      }
      if (!res?.vendorId) {
        setDisplayVendor(false);
        setVendorId(null);
      }
      setSelectedProject(res?.projectId);
    } catch (err) {
      console.log('err', err);
    } finally {
      setTransactionLoading(false);
    }
  };

  const getMerchant = async () => {
    try {
      const response = await getSingleMerchant(localTransaction?.vendorId);
      if (response?.status === 'active') {
        setVendors([...vendors, response]);
      }
    } catch (e) {
      console.log('error', e);
    }
  };

  const getAccountTypesApi = async () => {
    try {
      const response = await getAccountTypes();
      const filteredAccountTypes = response.filter((accountType) =>
        ['Income', 'Expenses'].includes(accountType.name),
      );
      setAccountTypes(filteredAccountTypes);
    } catch (e) {
      console.log('error', e);
    }
  };

  const getAccountSubTypesApi = async () => {
    try {
      const response = await getAccountSubTypes();
      setAccountSubTypes(response);
    } catch (e) {
      console.log('error', e);
    }
  };

  const handleAddedCategory = async (_, newCategory) => {
    const [accountsRes] = await Promise.all([
      getAccounts(),
      onChangeCategroy(newCategory?.id),
    ]);
    setAllModalAccounts(transformAccounts(accountsRes, 'allAccounts'));
    setAllCategories(
      transformAccounts(accountsRes, 'CATEGORY_PHYSICAL_ACCOUNT'),
    );
    setAllExpenseCategories(
      transformAccounts(accountsRes, 'CATEGORY_PHYSICAL_ACCOUNT', 'expense'),
    );
  };

  const transactionCurrency = useMemo(() => {
    let currency = '--';
    if (localTransaction?.currency) {
      currency = getCurrencySymbolFromIso(
        localTransaction?.currency,
        currencies,
      );
    }
    return currency;
  }, [localTransaction]);

  const handleAddTag = async (tag) => {
    try {
      const newTag = await createTag(tag);
      handleTagSelect(newTag?.id);
      const res = await getTags();
      setTags(res);
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleAddTax = async (tax) => {
    try {
      const response = await createTax(tax);
      if (response?.data?.tax?.id) {
        loadTaxes();
        const newTaxes = [...selectedTaxes, response?.data?.tax?.id];
        setSelectedTaxes(newTaxes);
        const transRes = await updateTransaction(localTransaction?.id, {
          taxes: newTaxes,
        });
        setLocalTransaction(transRes);
        reloadTransaction({ scrollToTop: false });
      }
    } catch (e) {
      console.log('error', e);
    }
  };

  const findSplitParent = async (transaction) => {
    loadTransaction(true, transaction?.splitParentId);
  };

  const deleteTransactions = async () => {
    const data = {
      ids: deleteTransactionModal,
    };
    try {
      setDeleteModalLoading(true);
      await deleteTransactionsByIDs(data);
      await reloadTransaction({ scrollToTop: false });
      toast.success('Transaction deleted successfully.');
      setDeleteTransactionModal('');
      setTransactionPanelOpen(false);
      setDeleteModalLoading(false);
    } catch (err) {
      if (err && err.includes('split transaction')) {
        const splitTran = await getSingleTransaction(data.ids[0]);
        if (splitTran?.splitParentId) {
          const parent = await findSplitParent(splitTran);
          setDeleteModalParent(parent);
        } else {
          setDeleteModalParent(splitTran);
        }
        setDeleteTransactionModal('split');
      } else {
        setDeleteTransactionModal('error');
      }
      setDeleteModalLoading(false);
      console.log('err', err);
      // toast.error("Deleting selected transactions is not permitted");
    }
  };

  const getReviewButtonTooltipText = (t) =>
    t?.reconciled
      ? 'Transaction Reconciled'
      : t?.pending
        ? 'Cannot be reviewed while pending'
        : t?.billTransaction?.billInstanceId
          ? 'Cannot be edited while assigned to a bill'
          : t?.invoiceTransaction?.invoiceId
            ? 'Cannot be edited while assigned to an invoice'
            : !t?.categoryAccountId
              ? 'Cannot be reviewed if uncategorized'
              : t?.reviewed
                ? 'Reviewed'
                : '';

  return (
    <>
      <DeleteTransactionModal
        isOpen={deleteTransactionModal}
        setIsOpen={setDeleteTransactionModal}
        handleDeleteTransaction={deleteTransactions}
        parentSplit={deleteModalParent}
        setTransactionPanelOpen={setTransactionPanelOpen}
        setSelectedRow={setTransaction}
        deleteModalLoading={deleteModalLoading}
      />
      <AddCustomerModal
        isOpen={customerModalOpen}
        setIsOpen={setCustomerModalOpen}
        setSelectedCustomer={(newCustomer) => {
          setCustomers((prev) => [newCustomer, ...prev]);
          setCustomerProjectLoader(true);
          onChangeCustomer(newCustomer?.id);
        }}
        salesRep={salesRep}
      />
      <TaxModal
        isOpen={taxModalOpen}
        setIsOpen={setTaxModalOpen}
        handleAddTax={handleAddTax}
      />
      <TagModal
        isOpen={tagModalOpen}
        setIsOpen={setTagModalOpen}
        handleAddTag={handleAddTag}
      />
      <AccountsModal
        isOpen={accountModalOpen}
        setIsOpen={setAccountModalOpen}
        accountTypes={accountTypes}
        accountSubTypes={accountSubTypes}
        loadData={handleAddedCategory}
        taxes={taxes}
        loadTaxes={loadTaxes}
        accounts={allModalAccounts}
      />
      <ModalBasic
        modalOpen={vendorModalOpen}
        setModalOpen={setVendorModalOpen}
        title="Add Vendor"
        width="!w-[1088px]"
      >
        <VendorForm
          handleUploadVendorLogo={handleUploadVendorLogo}
          handleAddVendor={handleAddVendor}
          setFormOpen={setVendorModalOpen}
          isModalView
          activeVendor={null}
        />
      </ModalBasic>
      <div
        ref={panelContent}
        className={`absolute inset-0 bg-white sm:left-auto  transition-transform duration-200 ease-in-out z-40 ${
          transactionPanelOpen ? 'translate-x-0 shadow-xl' : 'translate-x-full'
        }`}
      >
        <div
          className="sticky top-16 bg-white dark:bg-gradient-to-b dark:from-slate-800 dark:to-slate-900 overflow-x-hidden overflow-y-auto no-scrollbar shrink-0  w-full sm:w-[510px] h-[calc(100dvh-64px)]"
          style={
            transactionPanelOpen
              ? { boxShadow: ' -10px 0px 15px 0px #D0D5DD80' }
              : {}
          }
        >
          <div className="sticky top-0 h-[3.875rem] bg-white z-10">
            <div className="flex justify-end pt-2.5 pr-2.5">
              <button
                className="ml-auto group"
                onClick={() => setTransactionPanelOpen(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                  className="h-5 w-5 fill-[#667085] group-hover:fill-slate-700"
                >
                  <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                </svg>
              </button>
            </div>
            <div className="relative flex flex-row gap-[1rem] sm:gap-[3.125rem] justify-start items-center mt-3 pb-[2px] w-full border-b-2 border-gray-300 pl-[1.625rem] bg-white whitespace-nowrap">
              <p
                className={`text-sm leading-6 font-normal cursor-pointer translate-y-1 ${
                  currentTab === 'details'
                    ? 'text-black border-b-2 border-indigo-500 '
                    : 'text-slate-600'
                }`}
                onClick={() => setCurrentTab('details')}
              >
                Transaction Details
              </p>

              {!transaction?.pending && (
                <>
                  <p
                    className={`text-sm leading-6 font-normal cursor-pointer translate-y-1 ${
                      currentTab === 'receipt'
                        ? 'text-black border-b-2 border-indigo-500 '
                        : 'text-slate-600'
                    }`}
                    onClick={() => setCurrentTab('receipt')}
                  >
                    Attachments
                  </p>

                  <p
                    className={`text-sm leading-6 font-normal cursor-pointer translate-y-1 ${
                      currentTab === 'comments'
                        ? 'text-black border-b-2 border-indigo-500'
                        : 'text-slate-600'
                    }`}
                    onClick={() => setCurrentTab('comments')}
                  >
                    Comments
                  </p>
                </>
              )}
            </div>
          </div>
          {isLoading || transactionLoading ? (
            <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-10">
              <svg
                width="29"
                height="29"
                viewBox="0 0 29 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="animate-spin w-10 h-10 fill-current shrink-0"
              >
                <path
                  d="M14.5 2.41699V7.25033"
                  stroke="#E48642"
                  strokeWidth="2.55882"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.5 21.749V26.5824"
                  stroke="#E48642"
                  strokeWidth="2.55882"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.95703 5.95703L9.37662 9.37662"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.623 19.6211L23.0426 23.0407"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2.41699 14.5H7.25033"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21.749 14.5H26.5824"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.95703 23.0407L9.37662 19.6211"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.623 9.37662L23.0426 5.95703"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                Fetching data, please wait...
              </p>
            </div>
          ) : (
            <>
              {currentTab === 'receipt' && (
                <ReceiptPanel
                  transaction={transaction}
                  setReceiptModalOpen={setReceiptModalOpen}
                  setActiveReceipt={setActiveReceipt}
                  reloadTransaction={reloadTransaction}
                  accessType={accessType}
                  VIEWER={VIEWER}
                />
              )}
              {currentTab === 'comments' && (
                <CommentsPanel
                  transaction={transaction}
                  reloadTransaction={reloadTransaction}
                  loadSingleTransaction={loadTransaction}
                />
              )}
              {currentTab === 'details' && (
                <>
                  <div
                    className="flex flex-col items-center border border-black border-opacity-5 bg-white mx-6 mt-10 rounded-xl"
                    style={{
                      boxShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.06)',
                    }}
                  >
                    <div className="flex justify-between items-start w-full px-6 pt-6 mb-2.5">
                      {/* Icon */}
                      <div className="text-center">
                        {localTransaction?.vendor?.logoUrl ||
                        localTransaction?.plaidCategoryIconUrl ? (
                          <img
                            className="inline-flex w-[3.313rem] h-[3.313rem] rounded-full"
                            src={
                              localTransaction?.vendor?.logoUrl ||
                              localTransaction?.plaidCategoryIconUrl
                            }
                            width="53"
                            height="53"
                            alt="Transaction 04"
                          />
                        ) : (
                          <div
                            className="w-[3.313rem] h-[3.313rem] rounded-full flex items-center justify-center bg-slate-400 dark:bg-slate-600"
                            style={{
                              backgroundColor:
                                localTransaction?.categoryAccount?.group
                                  ?.color || '',
                            }}
                          >
                            <div
                              className={`${
                                localTransaction?.categoryAccount?.group?.color
                                  ? getContrast(
                                      localTransaction?.categoryAccount?.group
                                        ?.color,
                                      'text-black',
                                      'text-white',
                                    )
                                  : 'text-black'
                              } text-sm font-normal uppercase`}
                            >
                              {localTransaction?.description
                                ? localTransaction.description
                                    .replace(/[^\w\s]/gi, '')
                                    .split(' ')
                                    .slice(0, 2)
                                    .map((word) => word.charAt(0))
                                    .join('')
                                : ''}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="flex items-center gap-[0.625rem]">
                        {checkPermission(
                          PermissionKeys.MANAGE_TRANSACTIONS,
                          accessType,
                          permissions,
                        ) && (
                          <>
                            {/* Review Button  */}
                            {!isEditing && (
                              <Tooltip
                                content={`${getReviewButtonTooltipText(localTransaction)} `}
                                contentClassName="border-non text-center overflow-visible text-sm text-[#667085] relative"
                                position="bottom"
                                tooltipShow={
                                  !reviewedLoading &&
                                  getReviewButtonTooltipText(localTransaction)
                                }
                              >
                                {localTransaction?.reconciled ? (
                                  <div
                                    className="inline-flex text-xs text-[#78BD4F] gap-1 items-center cursor-auto"
                                    role="button"
                                    tabIndex="0"
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    Reconciled
                                    <svg
                                      width="12"
                                      height="15"
                                      viewBox="0 0 73 81"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M36.5 39C33.9785 39.0009 31.5415 39.9091 29.6344 41.5586C27.7274 43.2082 26.4776 45.489 26.1135 47.984C25.7495 50.4791 26.2955 53.0219 27.6517 55.1476C29.008 57.2733 31.0839 58.84 33.5 59.5612V66C33.5 66.7956 33.8161 67.5587 34.3787 68.1213C34.9413 68.6839 35.7043 69 36.5 69C37.2957 69 38.0587 68.6839 38.6213 68.1213C39.1839 67.5587 39.5 66.7956 39.5 66V59.5612C41.9161 58.84 43.992 57.2733 45.3483 55.1476C46.7045 53.0219 47.2505 50.4791 46.8865 47.984C46.5224 45.489 45.2726 43.2082 43.3656 41.5586C41.4585 39.9091 39.0215 39.0009 36.5 39ZM36.5 54C35.61 54 34.74 53.7361 33.9999 53.2416C33.2599 52.7471 32.6831 52.0443 32.3425 51.2221C32.0019 50.3998 31.9128 49.495 32.0865 48.6221C32.2601 47.7492 32.6887 46.9474 33.318 46.318C33.9474 45.6887 34.7492 45.2601 35.6221 45.0865C36.495 44.9128 37.3998 45.0019 38.2221 45.3425C39.0443 45.6831 39.7471 46.2599 40.2416 46.9999C40.7361 47.74 41 48.61 41 49.5C41 50.6935 40.5259 51.8381 39.682 52.682C38.8381 53.5259 37.6935 54 36.5 54ZM66.5 27H54.5V18C54.5 13.2261 52.6036 8.64773 49.2279 5.27208C45.8523 1.89642 41.2739 0 36.5 0C31.7261 0 27.1477 1.89642 23.7721 5.27208C20.3964 8.64773 18.5 13.2261 18.5 18V27H6.5C4.9087 27 3.38258 27.6321 2.25736 28.7574C1.13214 29.8826 0.5 31.4087 0.5 33V75C0.5 76.5913 1.13214 78.1174 2.25736 79.2426C3.38258 80.3679 4.9087 81 6.5 81H66.5C68.0913 81 69.6174 80.3679 70.7426 79.2426C71.8679 78.1174 72.5 76.5913 72.5 75V33C72.5 31.4087 71.8679 29.8826 70.7426 28.7574C69.6174 27.6321 68.0913 27 66.5 27ZM24.5 18C24.5 14.8174 25.7643 11.7652 28.0147 9.51472C30.2652 7.26428 33.3174 6 36.5 6C39.6826 6 42.7348 7.26428 44.9853 9.51472C47.2357 11.7652 48.5 14.8174 48.5 18V27H24.5V18ZM66.5 75H6.5V33H66.5V75Z"
                                        fill="#78BD4F"
                                      />
                                    </svg>
                                  </div>
                                ) : (
                                  <button
                                    aria-controls="feedback-modal"
                                    onClick={() => {
                                      if (!localTransaction?.pending) {
                                        onReviewed(!localTransaction?.reviewed);
                                      }
                                    }}
                                    disabled={
                                      !checkPermission(
                                        PermissionKeys.MANAGE_TRANSACTIONS,
                                        accessType,
                                        permissions,
                                      ) ||
                                      localTransaction?.pending ||
                                      !localTransaction?.categoryAccountId ||
                                      !!localTransaction?.billTransaction
                                        ?.billInstanceId ||
                                      !!localTransaction?.invoiceTransaction
                                        ?.invoiceId ||
                                      reviewedLoading
                                    }
                                    type="button"
                                    className={` h-[26px] w-[72px] `}
                                  >
                                    {reviewedLoading ? (
                                      <button
                                        type="button"
                                        className=" h-7 w-full justify-center inline-flex items-center  px-4 bg-[#E486420D] border border-indigo-500 text-indigo-500 text-xs rounded-[5px] disabled:cursor-not-allowed"
                                        disabled
                                      >
                                        <svg
                                          width="16"
                                          height="16"
                                          viewBox="0 0 29 29"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="animate-spin w-4 h-4 fill-current shrink-0"
                                        >
                                          <path
                                            d="M14.5 2.41699V7.25033"
                                            stroke="#E48642"
                                            strokeWidth="2.55882"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M14.5 21.749V26.5824"
                                            stroke="#E48642"
                                            strokeWidth="2.55882"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M5.95703 5.95703L9.37662 9.37662"
                                            stroke="#E48642"
                                            strokeWidth="1.58955"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M19.623 19.6211L23.0426 23.0407"
                                            stroke="#E48642"
                                            strokeWidth="1.58955"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M2.41699 14.5H7.25033"
                                            stroke="#E48642"
                                            strokeWidth="1.58955"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M21.749 14.5H26.5824"
                                            stroke="#E48642"
                                            strokeWidth="1.58955"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M5.95703 23.0407L9.37662 19.6211"
                                            stroke="#E48642"
                                            strokeWidth="1.58955"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M19.623 9.37662L23.0426 5.95703"
                                            stroke="#E48642"
                                            strokeWidth="1.58955"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </button>
                                    ) : localTransaction?.reviewed ? (
                                      <div className="inline-flex text-xs text-[#78BD4F] gap-1 items-center">
                                        Reviewed
                                        <DoubleTickIcon />
                                      </div>
                                    ) : (
                                      !localTransaction?.pending && (
                                        <button
                                          type="button"
                                          className="h-7  w-[72px] bg-[#78BD4F] bg-opacity-5 transition-all border border-[#78BD4F] text-[#78BD4F] text-xs rounded-[5px]"
                                        >
                                          Confirm
                                        </button>
                                      )
                                    )}
                                  </button>
                                )}
                              </Tooltip>
                            )}
                            {!isEditing &&
                              !localTransaction?.reconciled &&
                              checkPermission(
                                PermissionKeys.MANAGE_TRANSACTIONS,
                                accessType,
                                permissions,
                              ) && (
                                <AddTaskBtn
                                  setModalOpen={setTaskModalOpen}
                                  modalOpen={taskModalOpen}
                                  dropdownOpen={taskDropdownOpen}
                                  setDropdownOpen={setTaskDropdownOpen}
                                  setSelectedTaskOption={setSelectedTaskOption}
                                />
                              )}
                            {/* Edit Btn */}
                            <Tooltip
                              content={`${
                                localTransaction?.reconciled
                                  ? 'Cannot be edited while Reconciled'
                                  : localTransaction?.pending
                                    ? 'Cannot be edited while pending'
                                    : localTransaction?.billTransaction
                                          ?.billInstanceId
                                      ? 'Cannot be edited while assigned to a bill'
                                      : localTransaction?.invoiceTransaction
                                            ?.invoiceId
                                        ? 'Cannot be edited while assigned to an invoice'
                                        : reviewed
                                          ? 'Cannot be edited once reviewed'
                                          : ''
                              }`}
                              contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
                              size="sm"
                              tooltipShow={Boolean(
                                localTransaction?.pending ||
                                  reviewed ||
                                  localTransaction?.reconciled ||
                                  localTransaction?.billTransaction
                                    ?.billInstanceId ||
                                  localTransaction?.invoiceTransaction
                                    ?.invoiceId,
                              )}
                            >
                              <button
                                aria-controls="feedback-modal"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (isEditing) {
                                    setIsEditing(false);
                                    onEditSubmit(
                                      localTransactionAmount,
                                      localName,
                                      localDate,
                                    );
                                  } else {
                                    setIsEditing(true);
                                  }
                                }}
                                type="button"
                                disabled={
                                  localTransaction?.reviewed ||
                                  localTransaction?.pending ||
                                  reviewed ||
                                  localTransaction?.reconciled ||
                                  localTransaction?.billTransaction
                                    ?.billInstanceId ||
                                  localTransaction?.invoiceTransaction
                                    ?.invoiceId
                                }
                                className={`border border-opacity-50 shadow-sm h-8 w-[4.625rem] inline-flex justify-between items-center py-[0.375rem] px-[0.625rem] rounded-md font-inter ${
                                  isEditing
                                    ? 'border-indigo-500'
                                    : 'border-gray-300'
                                }`}
                              >
                                <span
                                  className={`${
                                    isEditing
                                      ? 'text-sm pr-[0.2rem]'
                                      : 'text-sm pr-[0.563rem]'
                                  } font-semibold leading-5 text-slate-600 text-opacity-80`}
                                >
                                  {isEditing ? 'Save' : 'Edit'}
                                </span>
                                <svg
                                  width="19"
                                  height="19"
                                  viewBox="0 0 19 19"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-4 h-4"
                                >
                                  <path
                                    d="M10.2731 3.20041L4.11564 9.71791C3.88314 9.96541 3.65814 10.4529 3.61314 10.7904L3.33564 13.2204C3.23814 14.0979 3.86814 14.6979 4.73814 14.5479L7.15314 14.1354C7.49064 14.0754 7.96314 13.8279 8.19564 13.5729L14.3531 7.05541C15.4181 5.93041 15.8981 4.64791 14.2406 3.08041C12.5906 1.52791 11.3381 2.07541 10.2731 3.20041Z"
                                    stroke="#667085"
                                  />
                                  <path
                                    d="M9.24561 4.28711C9.56811 6.35711 11.2481 7.93961 13.3331 8.14961"
                                    stroke="#667085"
                                  />
                                  <path
                                    d="M2.57812 17H16.0781"
                                    stroke="#667085"
                                  />
                                </svg>
                              </button>
                            </Tooltip>
                          </>
                        )}
                        {localUser?.realm === STAFF && (
                          <button
                            className="border border-opacity-50 shadow-sm h-8 w-[4.625rem] py-[0.375rem] px-[0.625rem] rounded-md font-inter border-gray-300 flex items-center justify-center"
                            onClick={() => setStaffModal(true)}
                          >
                            Data
                          </button>
                        )}
                      </div>
                    </div>

                    {/* Basic Transaction Info */}
                    <div className="h-full w-full flex justify-between items-end mt-[1.563rem] px-6 relative">
                      {/* Cost mb-[1.563rem] gap-[1.563rem] */}
                      <div className="flex flex-col min-h-full items-start justify-between">
                        {isEditing ? (
                          <>
                            {localTransaction?.split ? (
                              <Tooltip
                                content={
                                  <>
                                    <p>Amount cannot be edited for</p>
                                    <p>a split child transaction.</p>
                                  </>
                                }
                                position="top-right"
                                className="w-full"
                                contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
                                size="sm"
                                tooltipShow={localTransaction?.splitParentId}
                              >
                                <div
                                  className={`text-2xl font-medium leading-6 ${panelAmountColor(
                                    localTransactionAmount,
                                  )}`}
                                >
                                  {localTransaction?.amount
                                    ?.toString()
                                    .charAt(0) === '-'
                                    ? '+'
                                    : '-'}
                                  {transactionCurrency}
                                  {localTransaction?.amount
                                    ?.toString()
                                    .charAt(0) === '-'
                                    ? parseFloat(
                                        localTransaction?.amount
                                          ?.toString()
                                          .replace('-', ''),
                                      )?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })
                                    : parseFloat(
                                        localTransaction?.amount,
                                      )?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                </div>
                              </Tooltip>
                            ) : (
                              <div>
                                <span className="bg-white absolute left-6 text-2xl text-black border-solid border-b border-gray-300">
                                  {transactionCurrency}
                                </span>
                                <input
                                  type="number"
                                  value={localTransactionAmount}
                                  disabled={localTransaction?.splitParentId}
                                  onChange={handleAmountChange}
                                  className="!text-2xl font-medium leading-6 text-black border-0 border-solid border-b border-gray-300 appearance-none focus:ring-0 w-3/5 p-0 pl-4"
                                  onWheel={(e) => e.target.blur()}
                                />
                              </div>
                            )}
                            <input
                              type="text"
                              value={localName}
                              onChange={handleNameChange}
                              className="text-sm leading-6 text-slate-600 font-normal border-0 border-solid border-b border-gray-300 appearance-none focus:ring-0 w-[90%] p-0"
                            />
                            <Tooltip
                              content={
                                <>
                                  <p>Date cannot be edited for a</p>
                                  <p> split child transaction.</p>
                                </>
                              }
                              position="top-right"
                              className="w-full"
                              contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
                              size="sm"
                              tooltipShow={localTransaction?.splitParentId}
                            >
                              <DatePickerDash
                                className="text-sm leading-6 text-slate-600 font-normal border-0 border-solid border-b border-gray-300 appearance-none focus:ring-0 w-3/5 p-0"
                                value={localDate}
                                options={options}
                                onChange={(selectedDates) => {
                                  const formattedDate = selectedDates[0];
                                  handleDateChange(formattedDate);
                                }}
                                name="date"
                                id="date"
                                ref={flatPickrRef}
                                autoComplete="off"
                                onBlur={(e) => {
                                  if (
                                    !e.target.classList?.value?.includes(
                                      'active',
                                    )
                                  ) {
                                    handleDateChange(e.target.value);
                                  }
                                }}
                              />
                            </Tooltip>
                          </>
                        ) : (
                          <>
                            <div
                              className={`text-2xl font-medium leading-6 ${panelAmountColor(
                                localTransaction?.amount,
                              )}`}
                            >
                              {localTransaction?.amount
                                ?.toString()
                                .charAt(0) === '-'
                                ? '+'
                                : '-'}
                              {transactionCurrency}
                              {localTransactionAmount?.toString().charAt(0) ===
                              '-'
                                ? parseFloat(
                                    localTransactionAmount
                                      ?.toString()
                                      .replace('-', ''),
                                  )?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : parseFloat(
                                    localTransactionAmount,
                                  )?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                              {localTransaction?.currency !==
                                teamDefaultCurrency &&
                                ` (${localTransaction?.currency})`}
                            </div>
                            <p className="text-sm leading-6 text-slate-600 break-all font-normal mt-2 min-h-full w-[120%]">
                              {localTransaction?.description}
                            </p>
                            <div className="text-sm leading-6 text-slate-600 font-medium pb-1 mt-4">
                              {/* 22/01/2022, 8:56 PM */}
                              {moment
                                ?.utc(localTransaction?.authorizedDate)
                                ?.format('MMM DD, YYYY')}
                            </div>
                          </>
                        )}
                      </div>
                      {/* Transaction status */}
                      <div className="inline-flex items-end">
                        <div
                          className={`text-sm leading-6 inline-flex font-normal rounded-[0.313rem] text-center px-5 py-1 mt-4 ${statusColor(
                            localTransaction?.reconciled
                              ? 'Completed'
                              : localTransaction?.excluded
                                ? 'Canceled'
                                : localTransaction?.pending
                                  ? 'Canceled'
                                  : reviewed
                                    ? 'Completed'
                                    : '',
                          )}`}
                        >
                          {' '}
                          {localTransaction?.reconciled
                            ? 'Reconciled'
                            : localTransaction?.excluded
                              ? 'Excluded'
                              : localTransaction?.pending
                                ? 'Pending'
                                : reviewed
                                  ? 'Reviewed'
                                  : 'Posted'}
                        </div>
                      </div>
                    </div>
                    <svg
                      className="w-full mt-[1.563rem]"
                      width="400"
                      height="2"
                      viewBox="0 0 400 2"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.16309 1.5L399.163 0.5"
                        stroke="#D0D5DD"
                        strokeOpacity="0.8"
                        strokeLinecap="round"
                        strokeDasharray="6 6"
                      />
                    </svg>
                    <div className="mt-4 mb-4 w-full flex justify-between px-6">
                      {accountsLoading ? (
                        <div className="w-full h-full flex justify-center items-center">
                          <Loader width="w-7" height="h-7" />
                        </div>
                      ) : (
                        <Tooltip
                          content={
                            localTransaction?.providerTransactionId
                              ? 'Bank feed transaction accounts cannot be edited'
                              : localTransaction?.reconciled
                                ? 'Cannot be edited while reconciled'
                                : localTransaction?.pending
                                  ? 'Cannot be edited while pending'
                                  : localTransaction?.billTransaction
                                        ?.billInstanceId
                                    ? 'Cannot be edited while assigned to a bill'
                                    : localTransaction?.invoiceTransaction
                                          ?.invoiceId
                                      ? 'Cannot be edited while assigned to an invoice'
                                      : localTransaction?.reviewed || reviewed
                                        ? 'Cannot be edited once reviewed'
                                        : !checkPermission(
                                              PermissionKeys.MANAGE_TRANSACTIONS,
                                              accessType,
                                              permissions,
                                            )
                                          ? 'You do not have permission to edit'
                                          : ''
                          }
                          className="w-full"
                          contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
                          size="sm"
                          tooltipShow={Boolean(
                            localTransaction?.providerTransactionId ||
                              localTransaction?.reviewed ||
                              localTransaction?.pending ||
                              reviewed ||
                              localTransaction?.reconciled ||
                              !!localTransaction?.billTransaction
                                ?.billInstanceId ||
                              !!localTransaction?.invoiceTransaction
                                ?.invoiceId ||
                              !checkPermission(
                                PermissionKeys.MANAGE_TRANSACTIONS,
                                accessType,
                                permissions,
                              ),
                          )}
                        >
                          <div
                            onClick={() =>
                              setAccountDropdownVisible(!accountDropdownVisible)
                            }
                            className="flex justify-between w-full"
                          >
                            <PaymentMethod
                              details={localTransaction}
                              institution={
                                localTransaction?.account?.institution
                              }
                              dropdown={
                                !localTransaction?.providerTransactionId
                              }
                              accounts={accounts}
                              type="panel"
                              reloadTransaction={reloadTransaction}
                              disabled={Boolean(
                                !checkPermission(
                                  PermissionKeys.MANAGE_TRANSACTIONS,
                                  accessType,
                                  permissions,
                                ) ||
                                  localTransaction?.reconciled ||
                                  localTransaction?.reviewed ||
                                  localTransaction?.pending ||
                                  reconcile ||
                                  reviewed ||
                                  localTransaction?.billTransaction
                                    ?.billInstanceId ||
                                  localTransaction?.invoiceTransaction
                                    ?.invoiceId ||
                                  (localTransaction?.split &&
                                    localTransaction?.splitParentId) ||
                                  localTransaction?.providerTransactionId,
                              )}
                              flyout
                              setLoader={setAccountsLoading}
                            />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                  {/* Below main card */}
                  {localTransaction?.split && (
                    <div className="flex mx-6 gap-3 p-4 border border-indigo-500 rounded-[12px] bg-[#E486420D] mt-7">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.99984 13.3337V10.0003M9.99984 6.66699H10.0082M18.3332 10.0003C18.3332 14.6027 14.6022 18.3337 9.99984 18.3337C5.39746 18.3337 1.6665 14.6027 1.6665 10.0003C1.6665 5.39795 5.39746 1.66699 9.99984 1.66699C14.6022 1.66699 18.3332 5.39795 18.3332 10.0003Z"
                          stroke="#E48642"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      {localTransaction?.splitParentId && (
                        <div>
                          <h4 className="text-sm leading-tight font-medium text-indigo-500">
                            This is a child transaction
                          </h4>

                          <div className="text-sm mt-1  leading-tight text-indigo-500">
                            To edit the amount and date, please open{' '}
                            <span
                              className="cursor-pointer underline "
                              onClick={() => findSplitParent(localTransaction)}
                            >
                              {' '}
                              Parent Transaction
                            </span>
                          </div>
                        </div>
                      )}
                      {!localTransaction?.splitParentId && (
                        <div>
                          <h4 className="text-sm leading-tight font-medium text-indigo-500">
                            This is a split transaction
                          </h4>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="flex flex-col w-full px-[1.563rem] items-start gap-[0.5rem] mt-[1.625rem]">
                    <div className="flex flex-col gap-[0.5rem] items-start self-stretch">
                      {(localTransaction?.type !== 'Transfer' ||
                        transaction?.type !== 'Transfer') && (
                        <div className="flex flex-col w-full items-start gap-1.5 self-stretch">
                          {!localTransaction?.reviewed &&
                            !reviewed &&
                            !localTransaction?.reconciled &&
                            !localTransaction?.pending &&
                            checkPermission(
                              PermissionKeys.MANAGE_TRANSACTIONS,
                              accessType,
                              permissions,
                            ) && (
                              <div className="flex gap-2 items-center">
                                {!displayVendor && (
                                  <button
                                    className="text-indigo-500 border border-indigo-500 bg-white rounded-[5px] h-8 text-sm px-1 cursor-pointer"
                                    onClick={() => setDisplayVendor(true)}
                                    type="button"
                                  >
                                    Add Vendor
                                  </button>
                                )}
                                {!displayCustomer && (
                                  <button
                                    type="button"
                                    className="text-indigo-500 border border-indigo-500 bg-white rounded-[5px] h-8 px-1 text-sm cursor-pointer"
                                    onClick={() => setDisplayCustomer(true)}
                                  >
                                    Add Customer
                                  </button>
                                )}
                              </div>
                            )}

                          {displayVendor && (
                            <>
                              <label className="text-slate-600 text-sm font-medium leading-5">
                                Vendor
                                {!localTransaction?.reconciled &&
                                  !localTransaction?.reviewed &&
                                  !localTransaction?.pending &&
                                  !reviewed &&
                                  checkPermission(
                                    PermissionKeys.MANAGE_TRANSACTIONS,
                                    accessType,
                                    permissions,
                                  ) &&
                                  !localTransaction?.billTransaction
                                    ?.billInstanceId &&
                                  !localTransaction?.invoiceTransaction
                                    ?.invoiceId && (
                                    <>
                                      <span
                                        className="ml-2 text-indigo-500 text-sm cursor-pointer"
                                        onClick={() => {
                                          onChangeMerchant(null);
                                          setDisplayVendor(false);
                                        }}
                                      >
                                        Remove Vendor
                                      </span>
                                      {/* <span className="text-black mx-1">
                                            |
                                          </span>
                                          <span
                                            className="text-sm text-indigo-500 cursor-pointer"
                                            onClick={() => {
                                              onChangeMerchant(null);
                                              setDisplayVendor(false);
                                              setDisplayCustomer(true);
                                            }}
                                          >
                                            Assign a Customer
                                          </span> */}
                                    </>
                                  )}
                              </label>
                              <Tooltip
                                content={
                                  localTransaction?.reconciled
                                    ? 'Cannot be edited while reconciled'
                                    : localTransaction?.pending
                                      ? 'Cannot be edited while pending'
                                      : localTransaction?.reviewed || reviewed
                                        ? 'Cannot be edited once reviewed'
                                        : localTransaction?.billTransaction
                                              ?.billInstanceId
                                          ? 'Cannot be edited while assigned to a bill'
                                          : localTransaction?.invoiceTransaction
                                                ?.invoiceId
                                            ? 'Cannot be edited while assigned to an invoice'
                                            : 'You do not have permission to edit'
                                }
                                className="w-full"
                                contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
                                size="sm"
                                tooltipShow={
                                  localTransaction?.reconciled ||
                                  localTransaction?.reviewed ||
                                  localTransaction?.pending ||
                                  reviewed ||
                                  localTransaction?.billTransaction
                                    ?.billInstanceId ||
                                  localTransaction?.invoiceTransaction
                                    ?.invoiceId ||
                                  !checkPermission(
                                    PermissionKeys.MANAGE_TRANSACTIONS,
                                    accessType,
                                    permissions,
                                  )
                                }
                              >
                                <MerchantsDropdown
                                  vendors={vendors}
                                  inActiveVendor={
                                    localTransaction?.vendor?.status ===
                                    'inactive'
                                      ? localTransaction?.vendor
                                      : {}
                                  }
                                  setVendors={setVendors}
                                  selectedMerchant={vendorId}
                                  setSelectedMerchant={(value, merchant) => {
                                    onChangeMerchant(value);
                                  }}
                                  isSetCategoryStyle
                                  accessType={accessType}
                                  disabled={
                                    localTransaction?.reconciled ||
                                    localTransaction?.reviewed ||
                                    localTransaction?.pending ||
                                    reviewed ||
                                    localTransaction?.billTransaction
                                      ?.billInstanceId ||
                                    localTransaction?.invoiceTransaction
                                      ?.invoiceId ||
                                    !checkPermission(
                                      PermissionKeys.MANAGE_TRANSACTIONS,
                                      accessType,
                                      permissions,
                                    )
                                  }
                                  addNewOptionButton
                                  handleNewOptionButton={() => {
                                    setVendorModalOpen(true);
                                  }}
                                />
                              </Tooltip>
                            </>
                          )}
                          {displayCustomer && (
                            <>
                              <label className="text-slate-600 text-sm font-medium leading-5">
                                Customer
                                {!localTransaction?.reconciled &&
                                  !localTransaction?.reviewed &&
                                  !localTransaction?.pending &&
                                  !reviewed &&
                                  !selectedProject &&
                                  !customerProjectLoader &&
                                  !localTransaction?.invoiceTransaction
                                    ?.invoiceId &&
                                  !localTransaction?.billTransaction
                                    ?.billInstanceId &&
                                  checkPermission(
                                    PermissionKeys.MANAGE_TRANSACTIONS,
                                    accessType,
                                    permissions,
                                  ) && (
                                    <>
                                      <span
                                        className="ml-2 text-indigo-500 text-sm cursor-pointer"
                                        onClick={() => {
                                          if (!localTransaction?.projectId) {
                                            setCustomerProjectLoader(true);
                                            onChangeCustomer(null);
                                            setDisplayCustomer(false);
                                            setDropdownCustomers([]);
                                          }
                                        }}
                                      >
                                        Remove Customer
                                      </span>
                                      {/* <span className="text-black mx-1">
                                            |
                                          </span>
                                          <span
                                            className="text-sm text-indigo-500 cursor-pointer"
                                            onClick={() => {
                                              onChangeCustomer(null);
                                              setDisplayCustomer(false);
                                              setDisplayVendor(true);
                                            }}
                                          >
                                            Assign a Vendor
                                          </span> */}
                                    </>
                                  )}
                              </label>
                              {customerProjectLoader ? (
                                <div className="w-full h-full flex justify-center items-center">
                                  <Loader width="w-7" height="h-7" />
                                </div>
                              ) : (
                                <Tooltip
                                  content={
                                    selectedProject
                                      ? 'Cannot assign a different customer than the selected project customer'
                                      : localTransaction?.reconciled
                                        ? 'Cannot be edited while reconciled'
                                        : localTransaction?.pending
                                          ? 'Cannot be edited while pending'
                                          : localTransaction?.reviewed ||
                                              reviewed
                                            ? 'Cannot be edited once reviewed'
                                            : localTransaction?.billTransaction
                                                  ?.billInstanceId
                                              ? 'Cannot be edited while assigned to a bill'
                                              : localTransaction
                                                    ?.invoiceTransaction
                                                    ?.invoiceId
                                                ? 'Cannot be edited while assigned to an invoice'
                                                : 'You do not have permission to edit'
                                  }
                                  className="w-full"
                                  contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
                                  size="sm"
                                  tooltipShow={
                                    selectedProject ||
                                    localTransaction?.reconciled ||
                                    localTransaction?.reviewed ||
                                    localTransaction?.pending ||
                                    reviewed ||
                                    localTransaction?.billTransaction
                                      ?.billInstanceId ||
                                    localTransaction?.invoiceTransaction
                                      ?.invoiceId ||
                                    !checkPermission(
                                      PermissionKeys.MANAGE_TRANSACTIONS,
                                      accessType,
                                      permissions,
                                    )
                                  }
                                >
                                  <DropdownFull
                                    options={
                                      localTransaction?.customer?.status ===
                                      'inactive'
                                        ? [
                                            { ...localTransaction?.customer },
                                            ...dropdownCustomers,
                                          ]
                                        : dropdownCustomers
                                    }
                                    selected={customerId}
                                    setSelected={(value) => {
                                      setCustomerProjectLoader(true);
                                      onChangeCustomer(value);
                                    }}
                                    name="customer"
                                    scrollIntoView={false}
                                    search
                                    height="h-10"
                                    disabled={
                                      localTransaction?.reconciled ||
                                      localTransaction?.reviewed ||
                                      localTransaction?.pending ||
                                      reviewed ||
                                      localTransaction?.projectId
                                    }
                                    flyout
                                    placeholder="Choose customer"
                                    addNewOptionButton
                                    addNewOptionLabel="Create A New Customer"
                                    handleNewOptionButton={() => {
                                      setCustomerModalOpen(true);
                                    }}
                                    handleSearch={handleCustomerSearch}
                                    resetSearch={resetCustomerSearch}
                                    searchPlaceholder="Type To Search"
                                    searchLoading={searchCustomerLoading}
                                  />
                                </Tooltip>
                              )}
                            </>
                          )}
                        </div>
                      )}

                      <div className="flex flex-col w-full items-start gap-1.5 self-stretch">
                        {!isSplitting ||
                        (localTransaction?.split &&
                          localTransaction?.splitParentId) ? (
                          <>
                            <label className="text-slate-600 text-sm font-medium leading-5">
                              Category
                              {localTransaction?.invoiceTransaction
                                ?.invoiceId &&
                                (invoiceLoading ? (
                                  <span className="ml-2 inline-flex">
                                    <Loader width="w-4" height="w-4" />
                                  </span>
                                ) : (
                                  <span
                                    className="ml-2 underline text-sm cursor-pointer text-indigo-500"
                                    onClick={() => {
                                      handleRemoveTransactionFromInvoice();
                                    }}
                                  >
                                    Unassign from Invoice
                                  </span>
                                ))}
                              {localTransaction?.billTransaction
                                ?.billInstanceId &&
                                (billLoading ? (
                                  <span className="ml-2 inline-flex">
                                    <Loader width="w-4" height="w-4" />
                                  </span>
                                ) : (
                                  <span
                                    className="ml-2 underline text-sm cursor-pointer text-indigo-500"
                                    onClick={() => {
                                      handleRemoveTransactionFromBill();
                                    }}
                                  >
                                    Unassign from Bill
                                  </span>
                                ))}
                            </label>
                            {/*
                              {localTransaction?.providerCategory && (
                                <p className="text-[10px] font-medium text-slate-700 max-w-full break-words leading-tight">
                                  {localTransaction?.providerCategory}
                                </p>
                              )} */}
                            {categoryLoading ? (
                              <div className="w-full h-full flex justify-center items-center">
                                <Loader width="w-6" height="h-6" />
                              </div>
                            ) : (
                              <Tooltip
                                content={
                                  localTransaction?.reconciled
                                    ? 'Cannot be edited while reconciled'
                                    : localTransaction?.pending
                                      ? 'Cannot be edited while pending'
                                      : localTransaction?.billTransaction
                                            ?.billInstanceId
                                        ? 'Cannot be edited while assigned to a bill'
                                        : localTransaction?.invoiceTransaction
                                              ?.invoiceId
                                          ? 'Cannot be edited while assigned to an invoice'
                                          : localTransaction?.reviewed ||
                                              reviewed
                                            ? 'Cannot be edited once reviewed'
                                            : 'You do not have permission to edit'
                                }
                                className="w-full"
                                contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
                                size="sm"
                                tooltipShow={
                                  localTransaction?.reconciled ||
                                  localTransaction?.reviewed ||
                                  localTransaction?.pending ||
                                  reviewed ||
                                  localTransaction?.billTransaction
                                    ?.billInstanceId ||
                                  localTransaction?.invoiceTransaction
                                    ?.invoiceId ||
                                  !checkPermission(
                                    PermissionKeys.MANAGE_TRANSACTIONS,
                                    accessType,
                                    permissions,
                                  )
                                }
                              >
                                <CategoriesDropdown
                                  allCategories={
                                    localTransaction?.type === 'Income'
                                      ? allCategories
                                      : allExpenseCategories
                                  }
                                  height="h-10"
                                  allAccounts={accounts}
                                  isSetCategoryStyle
                                  selectedCategoryId={selectedCategory}
                                  setSelectedCategoryId={onChangeCategroy}
                                  selectedCategory={
                                    localTransaction?.categoryAccount
                                  }
                                  transactionAmount={localTransaction?.amount}
                                  accessType={accessType}
                                  handlePopupLogic={handlePopupLogic}
                                  transactionName={
                                    localTransaction?.description
                                  }
                                  disabled={
                                    localTransaction?.reconciled ||
                                    localTransaction?.reviewed ||
                                    localTransaction?.pending ||
                                    reviewed ||
                                    localTransaction?.billTransaction
                                      ?.billInstanceId ||
                                    localTransaction?.invoiceTransaction
                                      ?.invoiceId ||
                                    !checkPermission(
                                      PermissionKeys.MANAGE_TRANSACTIONS,
                                      accessType,
                                      permissions,
                                    )
                                  }
                                  physicalCategoryName={
                                    localTransaction?.categoryAccount?.subType
                                      ?.anchorTier === 'PHYSICAL_ACCOUNT' ||
                                    localTransaction?.categoryAccount?.subType
                                      ?.anchorTier === 'PHYSICAL_ACCOUNT'
                                      ? `Transfer: ${localTransaction?.categoryAccount?.name}`
                                      : localTransaction?.categoryAccount?.name
                                  }
                                  invoiceName={
                                    localTransaction?.invoiceTransaction
                                      ?.invoiceId &&
                                    `Invoice #${localTransaction?.invoiceTransaction?.invoice?.invoiceNumber
                                      ?.toString()
                                      ?.padStart(4, '0')} |
                                            ${
                                              localTransaction?.customer
                                                ?.customer
                                            }`
                                  }
                                  billName={
                                    localTransaction?.billTransaction
                                      ?.billInstanceId &&
                                    `Bill Payment to ${
                                      localTransaction?.vendor?.name
                                    }`
                                  }
                                  flyout
                                  type={localTransaction?.type}
                                  invoices={invoices}
                                  currencies={currencies}
                                  assignTransactionToInvoice={(id) => {
                                    handleAssignTransactionToInvoice(
                                      id,
                                      localTransaction?.id,
                                    );
                                  }}
                                  addNewOptionButton
                                  addNewOptionLabel="Create A New Category"
                                  handleNewOptionButton={() => {
                                    setAccountModalOpen(true);
                                  }}
                                  bills={allBills || bills || []}
                                  assignTransactionToBill={(
                                    billId,
                                    instance,
                                  ) => {
                                    handleAssignTransactionToBill(
                                      billId,
                                      instance,
                                      localTransaction?.id,
                                    );
                                  }}
                                />
                              </Tooltip>
                            )}

                            <>
                              {/* Transaction Splits Map  */}
                              {!localTransaction?.splitParentId &&
                              isSplitsLoading ? (
                                <div className="mt-4 w-full h-full flex flex-col justify-center items-center">
                                  <Loader width="w-7" height="h-7" />
                                  <p className="text-sm text-slate-400">
                                    Fetching transaction splits data...
                                  </p>
                                </div>
                              ) : localTransaction?.splits?.length > 0 &&
                                !localTransaction?.splitParentId ? (
                                <div className="w-full relative">
                                  <div className="absolute h-full pt-[38px] pb-[18px] pl-[11px]">
                                    <div className="h-full w-[2px] bg-gray-300" />
                                  </div>
                                  {/* Table */}
                                  {/* <label className="text-slate-600 text-sm leading-5 font-medium">
                                    Original Amount
                                    {localTransaction?.billTransaction
                                      ?.billInstanceId &&
                                      (billLoading ? (
                                        <span className="ml-2 inline-flex">
                                          <Loader width="w-4" height="w-4" />
                                        </span>
                                      ) : (
                                        <span
                                          className="ml-2 underline text-sm cursor-pointer text-indigo-500"
                                          onClick={() => {
                                            handleRemoveTransactionFromBill();
                                          }}
                                        >
                                          Unassign from Bill
                                        </span>
                                      ))}
                                  </label> */}
                                  <Tooltip
                                    content={
                                      localTransaction?.reconciled
                                        ? 'Cannot be edited while reconciled'
                                        : localTransaction?.pending
                                          ? 'Cannot be edited while pending'
                                          : localTransaction?.billTransaction
                                                ?.billInstanceId
                                            ? 'Cannot be edited while assigned to a bill'
                                            : localTransaction
                                                  ?.invoiceTransaction
                                                  ?.invoiceId
                                              ? 'Cannot be edited while assigned to an invoice'
                                              : localTransaction?.reviewed ||
                                                  reviewed
                                                ? 'Cannot be edited once reviewed'
                                                : 'You do not have permission to edit'
                                    }
                                    className="w-full"
                                    contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
                                    size="sm"
                                    tooltipShow={
                                      localTransaction?.reconciled ||
                                      localTransaction?.reviewed ||
                                      localTransaction?.pending ||
                                      reviewed ||
                                      localTransaction?.billTransaction
                                        ?.billInstanceId ||
                                      localTransaction?.invoiceTransaction
                                        ?.invoiceId ||
                                      !checkPermission(
                                        PermissionKeys.MANAGE_TRANSACTIONS,
                                        accessType,
                                        permissions,
                                      )
                                    }
                                  >
                                    <div className="relative flex justify-between items-center mt-2.5 mb-2.5 h-10 rounded-lg text-slate-600 bg-[#fbfdfa]">
                                      <span className="bg-[#f6f6f6] absolute left-4">
                                        {transactionCurrency}
                                      </span>
                                      <input
                                        value={formatNumberGlobally(
                                          parseFloat(
                                            localTransaction?.originalAmount *
                                              -1,
                                          ),
                                        )}
                                        type="number"
                                        className={`h-10 w-full bg-[#f6f6f6] px-6 outline-none pr-[50px] border rounded-[0.313rem] text-slate-600 leading-6 text-base font-normal border-[#D0D5DD]
                                                  ${
                                                    (localTransaction?.reconciled ||
                                                      localTransaction?.reviewed ||
                                                      localTransaction?.pending ||
                                                      reviewed ||
                                                      !checkPermission(
                                                        PermissionKeys.MANAGE_TRANSACTIONS,
                                                        accessType,
                                                        permissions,
                                                      )) &&
                                                    'cursor-default'
                                                  }`}
                                        readOnly
                                        onClick={(e) => {
                                          e.preventDefault();
                                          if (
                                            !localTransaction?.reconciled &&
                                            !localTransaction?.pending &&
                                            !localTransaction?.reviewed
                                          ) {
                                            setIsSplitting(true);
                                          }
                                        }}
                                        onWheel={(e) => e.target.blur()}
                                      />
                                    </div>
                                  </Tooltip>
                                  <div className="max-w-lg space-y-4">
                                    {localSplits?.map((s, i) => (
                                      <div
                                        key={i}
                                        className="ml-4 relative flex items-center w-full gap-4 bg-[#fbfdfa] dark:bg-slate-900/20 px-2.5 rounded-lg"
                                      >
                                        <div
                                          className="absolute top-[72%] left-3 w-[1.02rem] h-[2px] bg-gray-300"
                                          style={{
                                            transform: 'translate(-100%, -50%)',
                                          }}
                                        />
                                        <div className="rounded-lg text-slate-500 w-2/5 flex flex-col">
                                          <div className="flex flex-col gap-1.5">
                                            <label
                                              className={`text-slate-600 text-sm leading-5 font-medium ${
                                                s.amount.error
                                                  ? '!text-rose-500'
                                                  : ''
                                              }  `}
                                            >
                                              Split Amount
                                            </label>
                                            <Tooltip
                                              content={
                                                localTransaction?.reconciled
                                                  ? 'Cannot be edited while reconciled'
                                                  : localTransaction?.pending
                                                    ? 'Cannot be edited while pending'
                                                    : localTransaction
                                                          ?.billTransaction
                                                          ?.billInstanceId
                                                      ? 'Cannot be edited while assigned to a bill'
                                                      : localTransaction
                                                            ?.invoiceTransaction
                                                            ?.invoiceId
                                                        ? 'Cannot be edited while assigned to an invoice'
                                                        : localTransaction?.reviewed
                                                          ? 'Cannot be edited once reviewed'
                                                          : 'You do not have permission to edit'
                                              }
                                              className="w-full"
                                              contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
                                              size="sm"
                                              tooltipShow={
                                                localTransaction?.reconciled ||
                                                localTransaction?.reviewed ||
                                                localTransaction?.pending ||
                                                reviewed ||
                                                localTransaction
                                                  ?.billTransaction
                                                  ?.billInstanceId ||
                                                localTransaction
                                                  ?.invoiceTransaction
                                                  ?.invoiceId ||
                                                !checkPermission(
                                                  PermissionKeys.MANAGE_TRANSACTIONS,
                                                  accessType,
                                                  permissions,
                                                )
                                              }
                                            >
                                              <div className="relative">
                                                <input
                                                  placeholder="USD"
                                                  value={formatNumberGlobally(
                                                    parseFloat(s?.amount),
                                                  )}
                                                  readOnly
                                                  type="number"
                                                  className={`btn pl-[3rem] h-10 w-full sm:w-[11rem] pr-4 py-2.5 justify-between rounded-[0.313rem] font-normal leading-6 bg-white border-[#D0D5DD] placeholder:text-slate-600 placeholder:text-opacity-80 text-slate-600  ${
                                                    s.amount.error
                                                      ? '!border-rose-500'
                                                      : ''
                                                  }
                                                  ${
                                                    (localTransaction?.reconciled ||
                                                      localTransaction?.reviewed ||
                                                      localTransaction?.pending ||
                                                      reviewed ||
                                                      localTransaction
                                                        ?.billTransaction
                                                        ?.billInstanceId ||
                                                      localTransaction
                                                        ?.invoiceTransaction
                                                        ?.invoiceId ||
                                                      !checkPermission(
                                                        PermissionKeys.MANAGE_TRANSACTIONS,
                                                        accessType,
                                                        permissions,
                                                      )) &&
                                                    'cursor-default'
                                                  }`}
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    if (
                                                      !localTransaction?.reconciled &&
                                                      !localTransaction?.pending &&
                                                      !localTransaction?.reviewed &&
                                                      !localTransaction
                                                        ?.billTransaction
                                                        ?.billInstanceId &&
                                                      !localTransaction
                                                        ?.invoiceTransaction
                                                        ?.invoiceId
                                                    ) {
                                                      setIsSplitting(true);
                                                    }
                                                  }}
                                                  onWheel={(e) =>
                                                    e.target.blur()
                                                  }
                                                />
                                                <div className="absolute left-0 top-1/2 transform -translate-y-1/2">
                                                  <span className="ml-4 mr-2 text-slate-600 text-base leading-6 font-normal">
                                                    {transactionCurrency}
                                                  </span>
                                                  <span className="border-l-[0.038rem] h-4 bg-slate-600" />
                                                </div>
                                              </div>
                                            </Tooltip>
                                          </div>
                                        </div>
                                        <div className="rounded-lg text-slate-600 w-2/5 flex flex-col">
                                          <div className="flex flex-col gap-1.5 w-[11rem]">
                                            <label
                                              className={`text-slate-600 text-sm leading-5 font-medium ${
                                                s?.categoryAccountId?.error
                                                  ? '!text-rose-500'
                                                  : ''
                                              }  `}
                                            >
                                              Category
                                            </label>
                                            <Tooltip
                                              content={
                                                localTransaction?.reconciled
                                                  ? 'Cannot be edited while reconciled'
                                                  : localTransaction?.pending
                                                    ? 'Cannot be edited while pending'
                                                    : localTransaction
                                                          ?.billTransaction
                                                          ?.billInstanceId
                                                      ? 'Cannot be edited while assigned to a bill'
                                                      : localTransaction
                                                            ?.invoiceTransaction
                                                            ?.invoiceId
                                                        ? 'Cannot be edited while assigned to an invoice'
                                                        : localTransaction?.reviewed ||
                                                            reviewed
                                                          ? 'Cannot be edited once reviewed'
                                                          : 'You do not have permission to edit'
                                              }
                                              className="w-full"
                                              contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
                                              size="sm"
                                              tooltipShow={
                                                localTransaction?.reconciled ||
                                                localTransaction?.reviewed ||
                                                localTransaction?.pending ||
                                                reviewed ||
                                                !!localTransaction
                                                  ?.billTransaction
                                                  ?.billInstanceId ||
                                                !!localTransaction
                                                  ?.invoiceTransaction
                                                  ?.invoiceId ||
                                                !checkPermission(
                                                  PermissionKeys.MANAGE_TRANSACTIONS,
                                                  accessType,
                                                  permissions,
                                                )
                                              }
                                            >
                                              <CategoriesDropdown
                                                allCategories={
                                                  localTransaction?.type ===
                                                  'Income'
                                                    ? allCategories
                                                    : allExpenseCategories
                                                }
                                                selectedCategoryId={
                                                  s?.categoryAccountId ||
                                                  s?.categoryAccountId
                                                }
                                                readOnly
                                                isSetCategoryStyle
                                                isError={
                                                  s?.categoryAccountId?.error
                                                }
                                                flyout
                                                height="h-10"
                                                disabled={
                                                  localTransaction?.reconciled ||
                                                  localTransaction?.pending ||
                                                  localTransaction?.reviewed
                                                }
                                                handleCategoryClick={
                                                  handleCategoryClick
                                                }
                                                selectedCategoryName={
                                                  s?.categoryAccount?.mask
                                                    ? `${s?.categoryAccount?.name} (...${s?.categoryAccount?.mask})`
                                                    : s?.categoryAccount?.name
                                                }
                                              />
                                            </Tooltip>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              ) : (
                                !(
                                  localTransaction?.reconciled ||
                                  localTransaction?.categoryAccount?.group
                                    ?.type === Transfer ||
                                  localTransaction?.reviewed ||
                                  localTransaction?.pending ||
                                  reviewed ||
                                  !checkPermission(
                                    PermissionKeys.MANAGE_TRANSACTIONS,
                                    accessType,
                                    permissions,
                                  ) ||
                                  localTransaction?.type === 'Transfer' ||
                                  !!localTransaction?.billTransaction
                                    ?.billInstanceId ||
                                  !!localTransaction?.invoiceTransaction
                                    ?.invoiceId
                                ) &&
                                !transaction?.splitParentId &&
                                !transaction?.split && (
                                  <Tooltip
                                    content={
                                      localTransaction?.reconciled
                                        ? 'Cannot be edited while reconciled'
                                        : localTransaction?.categoryAccount
                                              ?.group?.type === Transfer ||
                                            localTransaction?.type ===
                                              'Transfer'
                                          ? 'Transfer and liability payment transactions cannot be split'
                                          : localTransaction?.pending
                                            ? 'Cannot be edited while pending'
                                            : localTransaction?.billTransaction
                                                  ?.billInstanceId
                                              ? 'Cannot be edited while assigned to a bill'
                                              : localTransaction
                                                    ?.invoiceTransaction
                                                    ?.invoiceId
                                                ? 'Cannot be edited while assigned to an invoice'
                                                : localTransaction?.reviewed ||
                                                    reviewed
                                                  ? 'Cannot be edited once reviewed'
                                                  : 'You do not have permission to edit'
                                    }
                                    contentClassName="border-none overflow-visible text-sm text-[#667085] relative left-[8rem]"
                                    position="bottom"
                                    size="sm"
                                    tooltipShow={
                                      localTransaction?.reconciled ||
                                      localTransaction?.categoryAccount?.group
                                        ?.type === Transfer ||
                                      localTransaction?.reviewed ||
                                      localTransaction?.pending ||
                                      reviewed ||
                                      !checkPermission(
                                        PermissionKeys.MANAGE_TRANSACTIONS,
                                        accessType,
                                        permissions,
                                      ) ||
                                      localTransaction?.billTransaction
                                        ?.billInstanceId ||
                                      localTransaction?.invoiceTransaction
                                        ?.invoiceId ||
                                      localTransaction?.type === 'Transfer'
                                    }
                                  >
                                    <button
                                      onClick={() => {
                                        setIsSplitting(true);
                                      }}
                                      type="button"
                                      disabled={
                                        localTransaction?.reconciled ||
                                        localTransaction?.categoryAccount?.group
                                          ?.type === Transfer ||
                                        localTransaction?.reviewed ||
                                        localTransaction?.pending ||
                                        reviewed ||
                                        !checkPermission(
                                          PermissionKeys.MANAGE_TRANSACTIONS,
                                          accessType,
                                          permissions,
                                        ) ||
                                        localTransaction?.type === 'Transfer' ||
                                        localTransaction?.billTransaction
                                          ?.billInstanceId ||
                                        localTransaction?.invoiceTransaction
                                          ?.invoiceId
                                      }
                                      className="text-sm font-medium leading-5 underline text-indigo-500"
                                    >
                                      Split Transaction
                                    </button>
                                  </Tooltip>
                                )
                              )}
                            </>
                          </>
                        ) : (
                          <SplitTransactionModal
                            isOpen={isSplitting}
                            setIsOpen={setSplitTransactionModalOpen}
                            closeSplitTransaction={closeSplitTransaction}
                            allCategories={allCategories}
                            transaction={localTransaction}
                            reloadTransactions={reloadTransaction}
                            isSelectBillTransaction={
                              isSplittedTransactionSelect
                            }
                            handleSplitModalSelectInstance={
                              handleSplitModalSelectInstance
                            }
                            type={transaction?.type}
                            transactionPanel
                            setTransactionPanelOpen={setTransactionPanelOpen}
                            selectedCategory={selectedCategory}
                            currency={transactionCurrency}
                            reloadCurrentTransaction={loadTransaction}
                          />
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col w-full items-start gap-1.5 self-stretch">
                      <p className="text-slate-600 text-sm font-medium leading-5">
                        Tags
                      </p>
                      <Tooltip
                        content={
                          localTransaction?.pending
                            ? 'Cannot be edited while pending'
                            : localTransaction?.billTransaction?.billInstanceId
                              ? 'Cannot be edited while assigned to a bill'
                              : localTransaction?.invoiceTransaction?.invoiceId
                                ? 'Cannot be edited while assigned to an invoice'
                                : !checkPermission(
                                      PermissionKeys.MANAGE_TRANSACTIONS,
                                      accessType,
                                      permissions,
                                    )
                                  ? 'You do not have permission to edit'
                                  : ''
                        }
                        className="w-full"
                        contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
                        size="sm"
                        tooltipShow={
                          !checkPermission(
                            PermissionKeys.MANAGE_TRANSACTIONS,
                            accessType,
                            permissions,
                          ) ||
                          localTransaction?.pending ||
                          !!localTransaction?.billTransaction?.billInstanceId ||
                          !!localTransaction?.invoiceTransaction?.invoiceId
                        }
                      >
                        <BasicCheckboxDropdown
                          selectedOptions={selectedTags}
                          setSelectedOptions={setSelectedTags}
                          options={
                            tags?.map((t) => ({
                              label: t?.name,
                              value: t?.id,
                            })) || []
                          }
                          label="Tags"
                          type="tag"
                          onApply={(tag) => {
                            handleTagSelect(tag);
                          }}
                          flyout
                          width="w-[30rem]"
                          disabled={
                            !checkPermission(
                              PermissionKeys.MANAGE_TRANSACTIONS,
                              accessType,
                              permissions,
                            ) ||
                            localTransaction?.reconciled ||
                            localTransaction?.pending ||
                            !!localTransaction?.billTransaction
                              ?.billInstanceId ||
                            !!localTransaction?.invoiceTransaction?.invoiceId
                          }
                          isAddOption
                          addOptionText="Add New Tag"
                          addOption={() => setTagModalOpen(true)}
                        />{' '}
                      </Tooltip>
                      {/* tags dropdown */}
                    </div>
                    <div className="flex flex-col w-full items-start gap-1.5 self-stretch">
                      <p className="text-slate-600 text-sm font-medium leading-5">
                        Taxes
                      </p>
                      {taxesLoading || categoryLoading ? (
                        <div className="w-full h-full flex justify-center items-center">
                          <Loader width="w-6" height="h-6" />
                        </div>
                      ) : (
                        <Tooltip
                          content={
                            localTransaction?.reconciled
                              ? 'Cannot be edited while reconciled'
                              : localTransaction?.pending
                                ? 'Cannot be edited while pending'
                                : localTransaction?.billTransaction
                                      ?.billInstanceId
                                  ? 'Cannot be edited while assigned to a bill'
                                  : localTransaction?.invoiceTransaction
                                        ?.invoiceId
                                    ? 'Cannot be edited while assigned to an invoice'
                                    : localTransaction?.reviewed || reviewed
                                      ? 'Cannot be edited once reviewed'
                                      : 'You do not have permission to edit'
                          }
                          className="w-full"
                          contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
                          size="sm"
                          tooltipShow={
                            localTransaction?.reconciled ||
                            localTransaction?.reviewed ||
                            localTransaction?.pending ||
                            reviewed ||
                            !!localTransaction?.billTransaction
                              ?.billInstanceId ||
                            !!localTransaction?.invoiceTransaction?.invoiceId ||
                            !checkPermission(
                              PermissionKeys.MANAGE_TRANSACTIONS,
                              accessType,
                              permissions,
                            )
                          }
                        >
                          <BasicCheckboxDropdown
                            selectedOptions={selectedTaxes}
                            setSelectedOptions={setSelectedTaxes}
                            options={
                              [...disabledTaxes, ...taxes]?.map((t) => ({
                                label: `${t?.name} (${+t?.percentage}%)`,
                                value: t?.id,
                              })) || []
                            }
                            label="No Taxes"
                            type="taxes"
                            onApply={(tax) => {
                              handleTaxSelect(tax);
                            }}
                            flyout
                            width="w-[30rem]"
                            disabled={
                              localTransaction?.reconciled ||
                              localTransaction?.reviewed ||
                              localTransaction?.pending ||
                              reviewed ||
                              !!localTransaction?.billTransaction
                                ?.billInstanceId ||
                              !!localTransaction?.invoiceTransaction
                                ?.invoiceId ||
                              !checkPermission(
                                PermissionKeys.MANAGE_TRANSACTIONS,
                                accessType,
                                permissions,
                              )
                            }
                            isAddOption
                            addOptionText="Add New Tax"
                            addOption={() => setTaxModalOpen(true)}
                          />{' '}
                        </Tooltip>
                      )}
                    </div>
                    {(localTransaction?.type === 'Expense' ||
                      transaction?.type === 'Expense') && (
                      <div className="flex flex-col w-full items-start gap-1.5 self-stretch">
                        <p className="text-slate-600 text-sm font-medium leading-5 flex items-center">
                          Projects
                          {projectLoading && false ? (
                            <span className="ml-2">
                              <Loader width="w-4" height="h-4" />
                            </span>
                          ) : (
                            selectedProject &&
                            !customerProjectLoader &&
                            !(
                              localTransaction?.reconciled ||
                              localTransaction?.pending ||
                              localTransaction?.reviewed ||
                              !checkPermission(
                                PermissionKeys.MANAGE_TRANSACTIONS,
                                accessType,
                                permissions,
                              )
                            ) && (
                              <span
                                className="ml-2 text-indigo-500 text-sm cursor-pointer"
                                onClick={() => {
                                  if (
                                    !(
                                      localTransaction?.reconciled ||
                                      localTransaction?.pending ||
                                      localTransaction?.reviewed ||
                                      !checkPermission(
                                        PermissionKeys.MANAGE_TRANSACTIONS,
                                        accessType,
                                        permissions,
                                      )
                                    )
                                  ) {
                                    setCustomerProjectLoader(true);
                                    handleProjectSelect(null);
                                  }
                                }}
                              >
                                Remove Project
                              </span>
                            )
                          )}
                        </p>
                        {customerProjectLoader ? (
                          <div className="w-full h-full flex justify-center items-center">
                            <Loader width="w-7" height="h-7" />
                          </div>
                        ) : (
                          <Tooltip
                            content={
                              localTransaction?.reconciled
                                ? 'Cannot be edited while reconciled'
                                : localTransaction?.pending
                                  ? 'Cannot be edited while pending'
                                  : localTransaction?.reviewed || reviewed
                                    ? 'Cannot be edited once reviewed'
                                    : !checkPermission(
                                          PermissionKeys.MANAGE_TRANSACTIONS,
                                          accessType,
                                          permissions,
                                        )
                                      ? 'You do not have permission to edit'
                                      : ''
                            }
                            className="w-full"
                            contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
                            size="sm"
                            tooltipShow={
                              localTransaction?.reconciled ||
                              localTransaction?.pending ||
                              localTransaction?.reviewed ||
                              reviewed ||
                              !checkPermission(
                                PermissionKeys.MANAGE_TRANSACTIONS,
                                accessType,
                                permissions,
                              )
                            }
                          >
                            <DropdownFull
                              options={
                                localTransaction?.project?.isDeleted
                                  ? [
                                      { ...localTransaction?.project },
                                      ...dropdownProjects,
                                    ]
                                  : dropdownProjects
                              }
                              selected={selectedProject}
                              setSelected={(value) => {
                                setSelectedProject(value);
                                setCustomerProjectLoader(true);
                                handleProjectSelect(value);
                              }}
                              name="name"
                              scrollIntoView={false}
                              search
                              height="h-10"
                              disabled={
                                localTransaction?.reconciled ||
                                localTransaction?.reviewed ||
                                localTransaction?.pending ||
                                reviewed ||
                                !checkPermission(
                                  PermissionKeys.MANAGE_TRANSACTIONS,
                                  accessType,
                                  permissions,
                                )
                              }
                              flyout
                              placeholder="Assign to Project"
                              handleSearch={handleProjectsSearch}
                              resetSearch={resetProjectsSearch}
                            />
                          </Tooltip>
                        )}
                      </div>
                    )}
                    <div className="flex flex-col w-full items-start gap-1.5 self-stretch">
                      <div className="text-slate-600 text-sm font-medium leading-5">
                        Notes
                      </div>

                      {savingNotes && (
                        <div className="flex items-center">
                          <svg
                            className="animate-spin w-4 h-4 fill-current shrink-0"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                          </svg>
                          <div style={{ fontSize: '0.7rem' }} className="ps-1">
                            Saving...
                          </div>
                        </div>
                      )}

                      <form className="w-full m-0 p-0">
                        <label className="sr-only" htmlFor="notes">
                          Write a note
                        </label>
                        <Tooltip
                          content={
                            localTransaction?.pending
                              ? 'Cannot be edited while pending'
                              : localTransaction?.reviewed
                                ? 'Cannot be edited once reviewed'
                                : 'You do not have permission to edit'
                          }
                          className="w-full"
                          contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
                          size="sm"
                          tooltipShow={
                            !checkPermission(
                              PermissionKeys.MANAGE_TRANSACTIONS,
                              accessType,
                              permissions,
                            )
                          }
                        >
                          <textarea
                            id="notes"
                            className="w-full rounded-[0.313rem] border border-gray-300 border-opacity-50 h-[8.25rem] text-slate-600 leading-6 text-base font-normal
                          placeholder:text-slate-600 placeholder:text-opacity-80 placeholder:leading-6 placeholder:text-base placeholder:font-normal disabled:cursor-not-allowed"
                            rows="4"
                            disabled={
                              !checkPermission(
                                PermissionKeys.MANAGE_TRANSACTIONS,
                                accessType,
                                permissions,
                              )
                            }
                            placeholder="Write a note…"
                            value={note}
                            onChange={(e) => noteChange(e.target.value)}
                          />
                        </Tooltip>
                      </form>
                    </div>
                    <div className="flex flex-col w-full items-start gap-1.5 self-stretch mb-2">
                      <div className="text-slate-600 text-sm font-medium leading-5">
                        Exclude Transaction
                      </div>
                      <div className="flex self-stretch items-start justify-between">
                        <div className="text-sm text-slate-600 text-opacity-70 font-normal w-4/5 leading-[1.125rem]">
                          If you exclude a transaction, it will not show up in
                          your spending and budget calculations.
                        </div>
                        <Tooltip
                          content={
                            localTransaction?.reconciled
                              ? 'Cannot be edited while reconciled'
                              : localTransaction?.pending
                                ? 'Cannot be edited while pending'
                                : localTransaction?.billTransaction
                                      ?.billInstanceId
                                  ? 'Cannot be edited while assigned to a bill'
                                  : localTransaction?.invoiceTransaction
                                        ?.invoiceId
                                    ? 'Cannot be edited while assigned to an invoice'
                                    : reviewed || localTransaction?.reviewed
                                      ? 'Cannot be edited once reviewed'
                                      : ''
                          }
                          contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
                          position="left"
                          size="sm"
                          tooltipShow={
                            localTransaction?.reviewed ||
                            localTransaction?.pending ||
                            reviewed ||
                            localTransaction?.reconciled ||
                            !!localTransaction?.billTransaction
                              ?.billInstanceId ||
                            !!localTransaction?.invoiceTransaction?.invoiceId
                          }
                        >
                          <div className="form-switch form-switch-indigo">
                            <input
                              type="checkbox"
                              id="switch-1"
                              className="sr-only"
                              checked={hidden}
                              disabled={
                                !checkPermission(
                                  PermissionKeys.MANAGE_TRANSACTIONS,
                                  accessType,
                                  permissions,
                                ) ||
                                localTransaction?.reviewed ||
                                localTransaction?.pending ||
                                reviewed ||
                                localTransaction?.reconciled ||
                                !!localTransaction?.billTransaction
                                  ?.billInstanceId ||
                                !!localTransaction?.invoiceTransaction
                                  ?.invoiceId
                              }
                              onChange={() => onHidden(!hidden)}
                            />
                            <label
                              className="bg-white border border-gray-300 border-opacity-50 shadow-sm"
                              htmlFor="switch-1"
                            >
                              <span
                                className="bg-gray-300"
                                aria-hidden="true"
                              />
                              <span className="sr-only">Switch label</span>
                            </label>
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                    <Tooltip
                      content="You do not have permission to delete"
                      className="w-fit"
                      contentClassName="translate-x-6 border-none overflow-visible text-sm text-[#667085] relative"
                      size="sm"
                      tooltipShow={
                        !checkPermission(
                          PermissionKeys.MANAGE_TRANSACTIONS,
                          accessType,
                          permissions,
                        )
                      }
                    >
                      <button
                        type="button"
                        className="mb-20 h-10 rounded-[5px] border border-rose-500 text-rose-500 px-4 bg-rose-500 bg-opacity-5 disabled:cursor-not-allowed disabled:opacity-50"
                        onClick={() =>
                          setDeleteTransactionModal([localTransaction?.id])
                        }
                        disabled={
                          !checkPermission(
                            PermissionKeys.MANAGE_TRANSACTIONS,
                            accessType,
                            permissions,
                          )
                        }
                      >
                        Delete Transaction
                      </button>
                    </Tooltip>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default TransactionDetails;
