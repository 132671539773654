import React, { useContext, useEffect, useRef, useState } from 'react';

import { useSearchParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import { all } from 'axios';
import { boot, showNewMessage, shutdown } from '@intercom/messenger-js-sdk';
import { useSelector } from 'react-redux';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import DeleteButton from '../../partials/actions/DeleteButton';
import SearchForm from '../../partials/actions/SearchForm';
import TransactionsTable from './TransactionsTable';
import PaginationClassic from '../../components/PaginationClassic';
import {
  bulkUpdateReviewTrans,
  deleteTransactionsByIDs,
  getAccounts,
  getBillById,
  getCategoriesGroups,
  getMerchants,
  getSingleTransaction,
  getTags,
  getTransactionMonthlyAnalytics,
  getTransactions,
  updateTransaction,
  updateTransactionTags,
  getCurrenciesData,
  getTeamById,
  updateTransactionCategory,
  getCustomers,
  bulkUpdateTransactionTags,
  bulkUpdateTransactionCategory,
  bulkUpdateTransactionVendor,
  getAllTeamsForUser,
  getInvoices,
  assignInvoiceToBill,
  getAutomations,
  deAssignTransToInvoice,
  getAccountSubTypes,
  getAccountTypes,
  getMatchingTransaction,
  getAllBills,
  getPayableBills,
  assignTransactionToBill,
  unassignTransactionToBill,
  getAllProjects,
  bulkUpdateTransactionCustomer,
  getTaxes,
  getMatchingSuggestionCount,
} from '../../API/backend_helper';
import TransactionDetails from './TransactionPanel';
import EditTransaction from './EditTransaction';
import ItemsSelectedDropdown from './ItemsSelectedDropdown';
import DateFilter from './DateFilter';
import AddTransactionModal from './AddTransactionModal';
import AddTransactionButton from './AddTransactionButton';
import {
  Expense,
  Income,
  Transfer,
  ACCOUNT_TYPES,
  TRANSACTION_TYPES,
  TRANSACTION_STATUS,
  TEAM_ID,
  transformAccounts,
  DevEnv,
  getFormattedDate,
  formatDateLocally,
  TEAM_DATE_FORMAT,
} from '../../utils/Utils';
import AccountsFilter from './Filters/AccountsFilter';
import CategoriesFilter from './Filters/CategoriesFilter';
import BasicCheckboxDropdown from '../component/BasicCheckboxDropdown';
import InstancesModal from '../bills/InstancesModal';
import SplitTransactionModal from './SplitTransactionModal';
import BillInstanceModal from '../bills/BillInstanceModal';
import FiltersModal from './Filters/FiltersModal';
import ReceiptModal from './ReceiptModal';
import DeleteTransactionModal from './DeleteTransactionModal';
import MerchantsDropdown from '../../components/MerchantsDropdown';
import emptyTransactionImg1 from '../../images/custom/payment-success-1.png';
import emptyTransactionImg2 from '../../images/custom/switch-1.png';
import emptyTransactionImg3 from '../../images/custom/text-us-1.png';
import BankWarning from '../../components/BankWarning';
import StaffModal from './StaffModal';
import StripeWarning from '../../components/StripeWarning';
import CreateRulePopup from '../../components/CreateRulePopup';
import CreateRuleModal from '../../components/CreateRuleModal';
import AddTaskModal from '../inbox/AddTaskModal';
import AccountsModal from '../accounts/AccountsModal';
import MatchingTransactionWarning from './MatchingTransactionWarning';
import Tooltip from '../../components/Tooltip';
import AppLayout from '../../components/AppLayout';
import ErrorsModal from './ErrorsModal';
import CategoryModal from './CategoryModal';

let searchTimeout = null;
let automationPoll = null;

function Transactions() {
  const { team } = useSelector((state) => state.Team);
  const teamDateFormat =
    team?.defaults?.dateFormat || localStorage.getItem(TEAM_DATE_FORMAT);
  const Env = process.env.REACT_APP_ENV;

  const pageRef = useRef();
  const { accessType, localUser, permissions } = useSelector(
    (state) => state.User,
  );
  const { currencies } = useSelector((state) => state.Currencies);

  const [searchParams] = useSearchParams();
  const accountId = searchParams.get('accountId');
  const billId = searchParams.get('billId');
  const vendorId = searchParams.get('vendor');
  const categoryId = searchParams.get('accountCategoryId');
  const startId = searchParams.get('startDate');
  const endId = searchParams.get('endDate');
  const transactionTypeId = searchParams.get('transactionTypes');

  const [categoryModalOpen, setCategoryModalOpen] = useState(null);
  const [errorsModalOpen, setErrorsModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [taskModalOpen, setTaskModalOpen] = useState(false);
  const [selectedTaskOption, setSelectedTaskOption] =
    useState('reviewTransaction');
  const [transType, setTransType] = useState(Income);

  const [selectedItems, setSelectedItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [categoryLoading, setCategoryLoading] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [transactionsGroupBy, setTransactionsGroupBy] = useState([]);
  const [allBalances, setAllBalances] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [rowDetailsOpen, setRowDetailsOpen] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [allExpenseCategories, setAllExpenseCategories] = useState([]);

  const [statusVal, setStatusVal] = useState('');
  const [searchVal, setSearchVal] = useState('');

  const [accounts, setAccounts] = useState([]);
  const [allAccounts, setAllAccounts] = useState([]);
  const [allModalAccounts, setAllModalAccounts] = useState([]); //For Accounts Modal
  const [orderByTable, setOrderByTable] = useState('');
  const [orderDirectionTable, setOrderDirectionTable] = useState('');
  const [accountCategories, setAccountCategories] = useState([]);
  const [isApplying, setIsApplying] = useState(false);

  const [tags, setTags] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [startDateFilter, setStartDateFilter] = useState('');
  const [endDateFilter, setEndDateFilter] = useState('');
  const [addTransactionModalOpen, setAddTransactionModalOpen] = useState(false);
  const [transactionsMonthlyAnalytics, setTransactionsMonthlyAnalytics] =
    useState([]);
  const [allFiltersShow, setAllFiltersShow] = useState(false);
  const [selectedBulkCategory, setSelectedBulkCategory] = useState(null);
  const [selectedBulkVendor, setSelectedBulkVendor] = useState(null);
  const [selectedBulkCustomer, setSelectedBulkCustomer] = useState(null);
  const [selectedBulkTag, setSelectedBulkTag] = useState([]);

  // Dropdown Filters
  const [selectedAccount, setSelectedAccount] = useState([]);
  const [selectedAccountTypes, setSelectedAccountTypes] = useState([]);
  const [selectedTransTypes, setSelectedTransTypes] = useState([]);
  const [selectedTransStatus, setSelectedTransStatus] = useState([]);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [showOnlyUncategorized, setShowOnlyUncategorized] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [categoryFilterSelected, setCategoryFilterSelected] = useState({});

  // Bill
  const [bill, setBill] = useState(null);
  const [billModalOpen, setBillModalOpen] = useState(false);
  const [billTransaction, setBillTransaction] = useState(null);
  const [splitTrasactionModalOpen, setSplitTransactionModalOpen] =
    useState(false);
  const [isSplittedTransactionSelect, setIsSplittedTransactionSelect] =
    useState(false);
  const [billInstanceModalOpen, setBillInstanceModalOpen] = useState(false);
  const [billActiveInstance, setBillActiveInstance] = useState(null);

  const [receiptModalOpen, setReceiptModalOpen] = useState(false);
  const [activeReceipt, setActiveReceipt] = useState(null);
  const [localLimit, setLocalLimit] = useState(50);
  const [currentTransDetailTab, setCurrentTransDetailTab] = useState('details');
  const [deleteTransactionModal, setDeleteTransactionModal] = useState('');
  const [deleteModalLoading, setDeleteModalLoading] = useState(false);
  const [failedEditCount, setFailedEditCount] = useState(0);
  const [failedVendorEditCount, setFailedVendorEditCount] = useState(0);
  const [failedCustomerEditCount, setFailedCustomerEditCount] = useState(0);
  const [deleteModalParent, setDeleteModalParent] = useState(null);
  const [numberOfDuplicates, setNumberOfDuplicates] = useState(0);
  const [numberOfMatching, setNumberOfMatching] = useState(0);
  const [numberOfUndepositMatching, setUndepositMaching] = useState(0);
  const [numberOfInvoicesMatching, setInvoiceMaching] = useState(0);
  const [numberOfBillsMatching, setBillsMaching] = useState(0);
  const [transactionWarningLoaded, setTransactionWarningLoaded] =
    useState(false);

  const [fromDropdown, setFromDropdown] = useState(false);
  const [sortByAmount, setSortByAmount] = useState(false);
  const [sortByName, setSortByName] = useState(false);
  const [transactionsExist, setTransactionsExist] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [allInvoices, setAllInvoices] = useState([]);
  const [allBills, setAllBills] = useState([]);
  const [bills, setBills] = useState([]);
  const [popupOpen, setPopupOpen] = useState(false);
  const [ruleModalOpen, setRuleModalOpen] = useState(false);
  const [popupCategory, setPopupCategory] = useState(null);
  const [popupTransaction, setPopupTransaction] = useState(null);
  const [isPolling, setIsPolling] = useState(false);
  const [currentDropdownId, setCurrentDropdownId] = useState(null);
  const [projects, setProjects] = useState([]);
  const [addTransactionBtnDisabled, setAddTransactionBtnDisabled] =
    useState(true);

  // Staff
  const [staffModal, setStaffModal] = useState(false);

  // Inline category creation
  const [accountModalOpen, setAccountModalOpen] = useState(false);
  const [accountTypes, setAccountTypes] = useState([]);
  const [accountSubTypes, setAccountSubTypes] = useState([]);
  const [mainType, setMainType] = useState({});
  const [allAccountTypes, setAllAccountTypes] = useState([]);

  //New Added Category for Category Modal
  const [newAddedCategory, setNewAddedCategory] = useState(null);

  document.title = 'COUNT | Transactions';
  const navigate = useNavigate();

  useEffect(() => {
    if (
      permissions?.length > 0 &&
      !checkPermission(
        PermissionKeys.VIEW_TRANSACTIONS,
        accessType,
        permissions,
      )
    ) {
      navigate('/');
    }
  }, [accessType, permissions]);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedTransactions(transactions?.transactions?.map((li) => li.id));
    if (selectAll) {
      setSelectedTransactions([]);
    }
  };

  // To check if there are more than 1 warnings to Show Popup
  const getIfMoreThan1Warnings = () => {
    let count = 0;
    if (numberOfDuplicates > 0) {
      count++;
    }
    if (numberOfMatching > 0) {
      count++;
    }
    if (numberOfUndepositMatching > 0) {
      count++;
    }
    if (numberOfInvoicesMatching > 0) {
      count++;
    }
    if (numberOfBillsMatching > 0) {
      count++;
    }
    return count;
  };

  const handleDeSelectAll = () => {
    setSelectAll(false);
    setSelectedTransactions([]);
  };

  const handleTransactionCheckbox = (e) => {
    e.stopPropagation();
    const { id } = e.target;
    setSelectAll(false);
    const parsedId = parseInt(id);
    if (selectedTransactions.includes(parsedId)) {
      setSelectedTransactions(
        selectedTransactions.filter((item) => item !== parsedId),
      );
    } else {
      setSelectedTransactions((prev) => [...prev, parsedId]);
    }
  };

  // Hide Selected Items
  const handleHideSelected = async (isInclude) => {
    try {
      await Promise.all(
        selectedTransactions.map((id) =>
          updateTransaction(id, {
            excluded: !isInclude,
          }),
        ),
      );
      await getTransactionsApi({ scrollToTop: false });
      setSelectedTransactions([]);
    } catch (e) {
      toast.error(
        'An error occurred while saving. Please refresh and try again.',
      );
    }
  };

  // Mark Reviewed Selected Items
  const handleMarkReviewedSelected = async ({ reviewed = true }) => {
    try {
      let failCount = 0;
      const availableTransactions = [];
      selectedTransactions.map((id) => {
        const item = transactions?.transactions?.find((item) => item.id === id);
        if (
          item?.billTransaction?.billInstanceId ||
          item?.reconciled ||
          item?.invoiceTransaction?.invoiceId ||
          item?.pending
        ) {
          failCount++;
        } else {
          availableTransactions.push(id);
        }
      });
      await bulkUpdateReviewTrans({
        transactions: availableTransactions,
        reviewed,
      });
      await getTransactionsApi({ scrollToTop: false });
      setSelectedTransactions([]);
      setSelectAll(false);
      if (availableTransactions.length > 0) {
        if (reviewed) {
          toast.success('Transactions marked as reviewed successfully.');
        } else {
          toast.success('Transactions marked as unreviewed successfully.');
        }
      }
      if (failCount > 0) {
        setDeleteTransactionModal('editFailReview');
        setFailedEditCount(failCount);
      }
    } catch (e) {
      toast.error(
        'An error occurred while saving. Please refresh and try again.',
      );
    }
  };

  // Set Category to Selected Items
  const handleCategorySelected = async (
    categoryAccountId,
    availableTransactions,
  ) => {
    try {
      setCategoryLoading((prevState) => {
        const newState = { ...prevState };
        availableTransactions.forEach((id) => {
          newState[id] = true;
        });
        return newState;
      });
      const data = {
        categoryAccountId,
        transactions: availableTransactions,
      };
      await bulkUpdateTransactionCategory(data);
    } catch (e) {
      toast.error(
        'An error occurred while saving. Please refresh and try again.',
      );
    }
    setCategoryLoading((prevState) => {
      const newState = { ...prevState };
      selectedTransactions.forEach((id) => {
        newState[id] = false;
      });
      return newState;
    });
  };

  // Add Tag to Selected Items
  const handleTagSelected = async (selectedTags, availableTransactions) => {
    try {
      const data = {
        tags: selectedTags,
        transactionIds: availableTransactions,
      };
      await bulkUpdateTransactionTags(data);
    } catch (e) {
      toast.error(
        'An error occurred while saving. Please refresh and try again.',
      );
    }
  };

  // Add vendor to Selected Items
  const handleVendorSelected = async (vendorId, availableTransactions) => {
    try {
      const data = {
        vendorId,
        transactionIds: availableTransactions,
      };
      await bulkUpdateTransactionVendor(data);
    } catch (e) {
      toast.error(
        'An error occurred while saving. Please refresh and try again.',
      );
    }
  };

  // Add vendor to Selected Items
  const handleCustomerSelected = async (customerId, availableTransactions) => {
    try {
      const data = {
        customerId,
        transactionIds: availableTransactions,
      };
      await bulkUpdateTransactionCustomer(data);
    } catch (e) {
      toast.error(
        'An error occurred while saving. Please refresh and try again.',
      );
    }
  };

  const handleBulkApply = async () => {
    try {
      let failCount = 0;
      let vendorFail = 0;
      let customerFail = 0;
      const availableTransactions = [];
      setIsApplying(true);
      setIsLoading(true);
      selectedTransactions?.forEach((id) => {
        const item = transactions?.transactions?.find((item) => item.id === id);
        if (item?.reviewed || item?.reconciled) {
          failCount++;
        } else {
          availableTransactions.push(id);
        }
      });
      if (selectedBulkVendor) {
        const vendorTransactions = [];
        availableTransactions.forEach((id) => {
          // const item = transactions?.transactions?.find(
          //   (item) => item.id === id,
          // );
          // if (item?.customerId) {
          //   vendorFail++;
          // } else {
          vendorTransactions.push(id);
          // }
        });
        if (vendorTransactions.length > 0) {
          await handleVendorSelected(selectedBulkVendor, vendorTransactions);
        }
      }
      if (selectedBulkCustomer) {
        const customerTransactions = [];
        availableTransactions.forEach((id) => {
          // const item = transactions?.transactions?.find(
          //   (item) => item.id === id,
          // );
          // if (item?.vendorId) {
          //   customerFail++;
          // } else {
          customerTransactions.push(id);
          // }
        });
        if (customerTransactions.length > 0) {
          await handleCustomerSelected(
            selectedBulkCustomer,
            customerTransactions,
          );
        }
      }
      if (selectedBulkTag.length > 0 && selectedTransactions?.length > 0) {
        await handleTagSelected(selectedBulkTag, selectedTransactions);
      }
      if (selectedBulkCategory && availableTransactions?.length > 0) {
        await handleCategorySelected(
          selectedBulkCategory,
          availableTransactions,
        );
      }
      await getTransactionsApi({ scrollToTop: false, loading: true });
      setSelectedTransactions([]);
      setSelectedBulkTag([]);
      setSelectedBulkVendor(null);
      setSelectedBulkCustomer(null);
      setSelectedBulkCategory(null);
      setSelectAll(false);
      setIsApplying(false);
      if (failCount > 0 || vendorFail > 0 || customerFail > 0) {
        setDeleteTransactionModal('editFail');
        setFailedEditCount(failCount);
        setFailedVendorEditCount(vendorFail);
        setFailedCustomerEditCount(customerFail);
      } else {
        setFailedEditCount(0);
        setFailedVendorEditCount(0);
        setFailedCustomerEditCount(0);
      }
      toast.success('Bulk transactions action has been successfully completed');
    } catch (e) {
      toast.error(
        'An error occurred while applying. Please refresh and try again.',
      );
      setIsApplying(false);
    }
  };

  const convertTransactionIntoGroups = (
    transactionsRes,
    tMA = transactionsMonthlyAnalytics,
  ) => {
    if (!transactionsRes?.transactions) {
      return [];
    }
    const groupedTransactions = transactionsRes?.transactions?.reduce(
      (grouped, transaction) => {
        const year = moment?.utc(transaction.authorizedDate)?.format('YYYY');
        const month = moment?.utc(transaction.authorizedDate)?.month() + 1;

        const key = `${year}-${month}`;
        if (!grouped[key]) {
          grouped[key] = [];
        }

        grouped[key].push(transaction);
        return grouped;
      },
      {},
    );

    return Object.keys(groupedTransactions)?.map((key) => {
      const temp = tMA?.find((t) => {
        if (key === `${t?.year}-${t?.month}`) {
          return true;
        }
        return false;
      });
      // moment doest read month as 1 but as 01 so fixing that issue for Safari
      const m =
        parseInt(key?.slice(5)) < 10
          ? `0${parseInt(key?.slice(5))}`
          : parseInt(key?.slice(5));
      const y = key?.slice(0, 4);
      const d = `${y}-${m}`;
      const monthName =
        moment(d)?.format('MMMM') === moment().format('MMMM') &&
        moment(d)?.format('YYYY') === moment().format('YYYY')
          ? 'This Month'
          : moment(d)?.format('MMMM YYYY');
      return {
        yearMonth: key,
        monthName,
        income: temp?.income,
        expense: temp?.expense,
        sum: temp?.sum,
        count: temp?.count,
        transactions: groupedTransactions[key],
      };
    });
  };

  const getTransactionsApi = async ({
    limit = localLimit,
    page = transactions?.page,
    transactionTypes = selectedTransTypes || [],
    status = selectedTransStatus || [],
    accounts = selectedAccount || [],
    search = searchVal || '',
    startDate = startDateFilter
      ? getFormattedDate(startDateFilter, teamDateFormat)
      : '',
    endDate = endDateFilter
      ? getFormattedDate(endDateFilter, teamDateFormat)
      : '',
    scrollToTop = true,
    accountCategories = selectedCategoryIds || [],
    orderBy = orderByTable || '',
    orderDirection = orderDirectionTable || '',
    accountTypes = selectedAccountTypes || [],
    vendors = selectedVendors || [],
    tags = selectedTags || [],
    accountSubTypes = [],
    customers = selectedCustomers || [],
    loading = false,
  }) => {
    if (scrollToTop) {
      pageRef?.current.scrollTo({
        top: 0,
        left: 0,
      });
    }
    if (loading) {
      setIsLoading(true);
    }

    try {
      const transRes = await getTransactions({
        limit,
        page,
        transactionTypes,
        status,
        accounts,
        search,
        startDate,
        endDate,
        accountCategories,
        orderBy,
        orderDirection,
        accountTypes,
        accountSubTypes,
        vendors,
        tags,
        customers,
      });
      setTransactions(transRes);
      if (orderBy !== 'description' && orderBy !== 'amount') {
        const res = await getTransactionMonthlyAnalytics({
          status,
          transactionTypes,
          accounts,
          search,
          startDate,
          endDate,
          accountCategories,
          orderBy,
          orderDirection,
          accountTypes,
          vendors,
          tags,
          accountSubTypes,
          customers,
        });
        setTransactionsMonthlyAnalytics(res);
        setTransactionsGroupBy(convertTransactionIntoGroups(transRes, res));
      } else {
        setTransactionsGroupBy([{ transactions: transRes?.transactions }]);
      }
      setIsReset(false);
      const automationRes = await getAutomations();
      await checkAutomationProgress(automationRes?.data?.automations, 1);
    } catch (e) {
      console.log('error', e);
    } finally {
      if (loading) {
        setIsLoading(false);
      }
    }
  };

  const getMerchantsApi = async () => {
    try {
      const res = await getMerchants({ status: 'active' });
      setVendors(res?.vendors);
    } catch (e) {
      console.log('error', e);
    }
  };

  const getProjectsApi = async () => {
    try {
      const res = await getAllProjects({});
      const filteredProjects = res?.filter(
        (project) =>
          project?.status === 'Not started' ||
          project?.status === 'In progress',
      );
      setProjects(filteredProjects);
    } catch (e) {
      console.log('error', e);
    }
  };

  const getMatchingTransactionsApi = async () => {
    setTransactionWarningLoaded(false);
    try {
      const res = await getMatchingSuggestionCount();
      setNumberOfDuplicates(
        parseInt(res?.data?.result?.[0]?.duplicateCount || 0),
      );
      setNumberOfMatching(parseInt(res?.data?.result?.[0]?.transferCount || 0));
      setUndepositMaching(
        parseInt(res?.data?.result?.[0]?.undepositedCount || 0),
      );
      setInvoiceMaching(parseInt(res?.data?.result?.[0]?.invoiceCount || 0));
      setBillsMaching(parseInt(res?.data?.result?.[0]?.billCount || 0));

      setTransactionWarningLoaded(true);
    } catch (e) {
      setTransactionWarningLoaded(true);
      console.log('error', e);
    }
  };

  const getInvoicesApi = async () => {
    try {
      const resUnpaid = await getInvoices({
        type: 'invoice',
        limit: 100000,
        status: ['partial', 'unpaid', 'overdue', 'approved'],
      });
      const resAll = await getInvoices({
        type: 'invoice',
        limit: 100000,
      });
      setAllInvoices(resAll?.data?.invoices);
      setInvoices(resUnpaid?.data?.invoices);
    } catch (e) {
      console.log('error', e);
    }
  };

  const getBillsApi = async () => {
    try {
      const unpaidRes = await getAllBills({
        limit: 100000,
        approvalStatus: 'approved',
        status: ['unpaid', 'overdue', 'partial'],
      });
      const alldRes = await getAllBills({
        limit: 100000,
      });
      setAllBills(alldRes?.bills);
      setBills(unpaidRes?.bills);
    } catch (e) {
      console.log('error', e);
    }
  };
  const getCustomersApi = async () => {
    try {
      const res = await getCustomers({ status: 'active' });
      setCustomers(res?.data?.records);
    } catch (e) {
      console.log('error', e);
    }
  };

  const loadTaxes = async () => {
    try {
      const res = await getTaxes();
      setTaxes(res?.data?.taxes);
    } catch (err) {
      console.log('err', err);
    }
  };

  const getAccountTypesApi = async () => {
    try {
      const response = await getAccountTypes();
      setAllAccountTypes(response);
      const filteredAccountTypes = response.filter((accountType) =>
        ['Income', 'Expenses'].includes(accountType.name),
      );
      setAccountTypes(filteredAccountTypes);
    } catch (e) {
      console.log('error', e);
    }
  };

  const getAccountSubTypesApi = async () => {
    try {
      const response = await getAccountSubTypes();
      setAccountSubTypes(response);
    } catch (e) {
      console.log('error', e);
    }
  };

  const areFiltersApplied = () =>
    selectedAccount.length > 0 ||
    selectedAccountTypes.length > 0 ||
    selectedTransTypes.length > 0 ||
    selectedTransStatus.length > 0 ||
    selectedCategoryIds.length > 0 ||
    selectedTags.length > 0 ||
    selectedVendors.length > 0 ||
    selectedCustomers.length > 0 ||
    startDateFilter ||
    endDateFilter;

  const loadData = async () => {
    setIsLoading(true);
    setAddTransactionBtnDisabled(true);
    try {
      if (accountId !== null) {
        setSelectedAccount([+accountId]);
      }
      if (vendorId !== null) {
        setSelectedVendors([+vendorId]);
        setAllFiltersShow(true);
      }
      if (categoryId !== null) {
        setSelectedCategoryIds(
          categoryId.split(',').map((id) => parseInt(id, 10)),
        );
      }
      if (startId !== null) {
        setStartDateFilter(formatDateLocally(startId, teamDateFormat));
      }
      if (endId !== null) {
        setEndDateFilter(formatDateLocally(endId, teamDateFormat));
      }
      if (transactionTypeId !== null) {
        setSelectedTransTypes(transactionTypeId.split(','));
      }

      const [transRes, res] = await Promise.all([
        getTransactions({
          accounts: accountId !== null ? [accountId] : '',
          vendors: vendorId !== null ? [vendorId] : '',
          accountCategories: categoryId !== null ? [categoryId] : '',
          startDate:
            startId !== null
              ? getFormattedDate(
                  formatDateLocally(startId, teamDateFormat),
                  teamDateFormat,
                )
              : '',
          endDate:
            endId !== null
              ? getFormattedDate(
                  formatDateLocally(endId, teamDateFormat),
                  teamDateFormat,
                )
              : '',
          transactionTypes:
            transactionTypeId !== null ? [transactionTypeId] : '',
          limit: localLimit,
        }),
        getTransactionMonthlyAnalytics({
          accounts: accountId !== null ? [accountId] : '',
          vendors: vendorId !== null ? [vendorId] : '',
          accountCategories: categoryId !== null ? [categoryId] : '',
          startDate:
            startId !== null
              ? getFormattedDate(
                  formatDateLocally(startId, teamDateFormat),
                  teamDateFormat,
                )
              : '',
          endDate:
            endId !== null
              ? getFormattedDate(
                  formatDateLocally(endId, teamDateFormat),
                  teamDateFormat,
                )
              : '',
          transactionTypes:
            transactionTypeId !== null ? [transactionTypeId] : '',
          limit: localLimit,
        }),
      ]);

      setTransactions(transRes);
      transRes?.transactions?.length > 0 && setTransactionsExist(true);
      setTransactionsGroupBy(convertTransactionIntoGroups(transRes, res));
      setSelectedTransactions([]);
      setTransactionsMonthlyAnalytics(res);
      setIsLoading(false);
      getMerchantsApi();
      getCustomersApi();
      getProjectsApi();
      loadTaxes();
      const accountsRes = await getAccounts();

      if (accountsRes) {
        setAllModalAccounts(transformAccounts(accountsRes, 'allAccounts'));
        setAllAccounts(transformAccounts(accountsRes, 'PHYSICAL_ACCOUNT'));
        setAccounts(transformAccounts(accountsRes, 'PHYSICAL_ACCOUNT'));
        setAllBalances(accountsRes);
        setAccountCategories(
          transformAccounts(accountsRes, 'CATEGORY_PHYSICAL_ACCOUNT'),
        );
        setAllCategories(
          transformAccounts(accountsRes, 'CATEGORY_PHYSICAL_ACCOUNT'),
        );
        setAllExpenseCategories(
          transformAccounts(
            accountsRes,
            'CATEGORY_PHYSICAL_ACCOUNT',
            'expense',
          ),
        );
      }
      setAddTransactionBtnDisabled(false);
      await Promise.all([
        getAccountTypesApi(),
        getAccountSubTypesApi(),
        getInvoicesApi(),
        getMatchingTransactionsApi(),
        getBillsApi(),
      ]);
      // setIsLoading(false);

      const automationRes = await getAutomations();
      await checkAutomationProgress(automationRes?.data?.automations, 1);

      const resTags = await getTags();
      setTags(resTags);
    } catch (e) {
      setIsLoading(false);
      console.log('error', e);
    }
  };

  const assignTransactionToInvoice = async (invoiceId, transactionId) => {
    setCategoryLoading((prevState) => ({
      ...prevState,
      [transactionId]: true,
    }));
    try {
      await assignInvoiceToBill(invoiceId, { transactions: [transactionId] });
      getInvoicesApi();
      await getTransactionsApi({ scrollToTop: false });
      toast.success('Transaction assigned to invoice successfully.');
    } catch (e) {
      toast.error('An error occurred while assigning transaction to invoice.');
    }
    setCategoryLoading((prevState) => ({
      ...prevState,
      [transactionId]: false,
    }));
    setCurrentDropdownId(null);
  };

  const removeTransactionFromInvoice = async (invoiceId, transactionId) => {
    try {
      await deAssignTransToInvoice(invoiceId, { transactionId });
      toast.success('Transaction removed from invoice successfully.');
      getInvoicesApi();
      await getTransactionsApi({ scrollToTop: false });
    } catch (e) {
      toast.error('An error occurred while removing transaction from invoice.');
    }
  };

  const removeTransactionFromBill = async (billId, instanceId, data) => {
    try {
      await unassignTransactionToBill(billId, instanceId, data);
      toast.success('Transaction removed from bill successfully.');
      getBillsApi();
      await getTransactionsApi({ scrollToTop: false });
    } catch (e) {
      toast.error('An error occurred while removing transaction from bill.');
      console.log('error', e);
    }
  };

  const assignTransactionToBillApi = async (
    billId,
    instance,
    transactionId,
  ) => {
    setCategoryLoading((prevState) => ({
      ...prevState,
      [transactionId]: true,
    }));
    try {
      const res = await assignTransactionToBill(billId, instance, {
        transactionId,
      });
      getBillsApi();
      await getTransactionsApi({ scrollToTop: false });
      if (res?.id) {
        toast.success('Transaction assigned to bill successfully.');
      }
    } catch (e) {
      toast.error('An error occurred while assigning transaction to bill.');
    }
    setCategoryLoading((prevState) => ({
      ...prevState,
      [transactionId]: false,
    }));
    setCurrentDropdownId(null);
  };

  const handleExpenseCategories = (res) => {
    const expenseArray = [...res];

    expenseArray.sort((a, b) => {
      const order = { Expense: 1, Income: 2, Transfer: 3 };
      return (order[a.type] || 0) - (order[b.type] || 0);
    });

    return expenseArray;
  };

  const getAccount = (account_id) => {
    const filter = allBalances?.filter((bal) => bal?.id === account_id);
    if (filter?.length > 0) {
      return filter?.[0];
    }
    return {};
  };

  const handlePopupLogic = (category, transaction) => {
    setPopupCategory(category);
    setPopupTransaction(transaction);
    setPopupOpen(true);
  };

  const processingAutomations = new Set();

  const checkAutomationProgress = async (res, timeOut = 15000) => {
    if (res?.length > 0) {
      for (let i = 0; i < res.length; i++) {
        const itemId = res[i]?.id;
        if (res[i]?.createStatus === 'processing') {
          processingAutomations.add(itemId);
          clearTimeout(automationPoll);
          automationPoll = null;
          automationPoll = setTimeout(async () => {
            const newRes = await getAutomations();
            await checkAutomationProgress(newRes?.data?.automations);
          }, timeOut);
          break;
        } else if (
          res[i]?.createStatus === 'success' &&
          processingAutomations.has(itemId)
        ) {
          processingAutomations.delete(itemId);
          await getTransactionsApi({});
        }
      }
    }
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  useEffect(
    () => () => {
      clearTimeout(automationPoll);
    },
    [],
  );

  useEffect(() => {
    if (rowDetailsOpen) {
      shutdown();
    } else {
      boot();
    }
  }, [rowDetailsOpen]);

  useEffect(() => {
    setRowDetailsOpen(false);
  }, []);

  const onSearch = (val) => {
    setSearchVal(val);
    clearTimeout(searchTimeout);
    searchTimeout = null;
    searchTimeout = setTimeout(async () => {
      getTransactionsApi({
        search: encodeURIComponent(val || ''),
        page: 1,
        loading: true,
      });
    }, 900);
  };

  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
  };

  const handleSortBy = (value) => {
    if (value === 'amount') {
      setSortByAmount(true);
    } else if (value === 'description') {
      setSortByAmount(false);
      setSortByName(true);
    } else {
      setSortByAmount(false);
      setSortByName(false);
    }
    let orderByVal = '';
    let orderDirectionVal = '';
    if (!orderByTable && !orderDirectionTable) {
      orderByVal = value;
      orderDirectionVal = 'asc';
    } else if (orderByTable !== value) {
      orderByVal = value;
      orderDirectionVal = 'asc';
    } else if (orderByTable === value && orderDirectionTable === 'asc') {
      orderByVal = value;
      orderDirectionVal = 'desc';
    } else {
      orderByVal = '';
      orderDirectionVal = '';
    }
    setOrderByTable(orderByVal);
    setOrderDirectionTable(orderDirectionVal);
    getTransactionsApi({
      orderBy: orderByVal,
      orderDirection: orderDirectionVal,
      page: 1,
      loading: true,
    });
  };

  const onDateChange = async (startDate, endDate) => {
    await getTransactionsApi({
      startDate: startDate ? getFormattedDate(startDate) : '',
      endDate: endDate ? getFormattedDate(endDate) : '',
      page: 1,
      loading: true,
    });
  };
  // Assign Transaction To Bill Functions

  const loadBillById = async () => {
    try {
      const res = await getBillById(billId);
      setBill(res);
    } catch (err) {
      console.log('err', err);
    }
  };

  const deleteTransactions = async () => {
    const data = {
      ids:
        deleteTransactionModal === 'all'
          ? selectedTransactions
          : deleteTransactionModal,
    };
    try {
      setDeleteModalLoading(true);
      await deleteTransactionsByIDs(data);
      await getTransactionsApi({ scrollToTop: false });
      setSelectedTransactions([]);
      setSelectAll(false);
      toast.success('Transactions deleted successfully.');
      setDeleteTransactionModal('');
      setDeleteModalLoading(false);
    } catch (err) {
      if (err && err.includes('split transaction')) {
        const splitTran = await getSingleTransaction(data.ids[0]);
        if (splitTran?.splitParentId) {
          const parent = await findSplitParent(splitTran);
          setDeleteModalParent(parent);
        } else {
          setDeleteModalParent(splitTran);
        }
        setDeleteTransactionModal('split');
      } else {
        setDeleteTransactionModal('error');
      }
      setDeleteModalLoading(false);
      console.log('err', err);
      // toast.error("Deleting selected transactions is not permitted");
    }
  };

  const onRowClickForBill = (transaction) => {
    setBillTransaction(transaction);
    setBillModalOpen(true);
  };

  // Funcation call on Split Transaction button in Instance Modal
  const handleInstancesModalSplitTransactions = (transaction) => {
    setSelectedRow(transaction);
    setIsSplittedTransactionSelect(true);
    setSplitTransactionModalOpen(true);
  };

  // Function Call after Split Transactions and Select Transaction  for Bill
  const handleSplitModalSelectInstance = (transaction) => {
    setBillModalOpen(true);
    setBillTransaction(transaction);
  };

  const handleOpenInstaceModal = (instance) => {
    setBillActiveInstance(instance);
    setBillInstanceModalOpen(true);
  };

  const handleResetFilters = async () => {
    setIsLoading(true);
    setSelectedAccount([]);
    setSelectedCategoryIds([]);
    setSelectedCustomers([]);
    setStartDateFilter('');
    setEndDateFilter('');
    setSelectedTransTypes([]);
    setSelectedAccountTypes([]);
    setSelectedTransStatus([]);
    setSelectedTags([]);
    setSelectedVendors([]);
    setSortByAmount(false);
    setShowOnlyUncategorized(false);
    setSortByName(false);
    setIsReset(true);
    setSearchVal('');
    await getTransactionsApi({
      status: [],
      accounts: [],
      startDate: '',
      endDate: '',
      scrollToTop: false,
      accountCategories: [],
      accountTypes: [],
      vendors: [],
      tags: [],
      customers: [],
      search: '',
      transactionTypes: [],
      orderBy: '',
      orderDirection: '',
      accountSubTypes: [],
    });
    setIsLoading(false);
  };

  useEffect(() => {
    if (billId) {
      loadBillById();
    }
  }, [billId]);

  const findSplitParent = async (transaction) => {
    const res = await getSingleTransaction(transaction?.splitParentId);
    setSelectedRow(res);
    return res;
  };

  const onShowUncategorized = async (bool) => {
    setCategoryFilterSelected({});
    setShowOnlyUncategorized(bool);
    const uncategorizedIds = [];

    if (bool) {
      accountCategories.forEach((accountCategory) => {
        const uncategorized = accountCategory.categories.filter((category) =>
          category.name.startsWith('Uncategorized'),
        );
        uncategorizedIds.push(...uncategorized.map((cat) => cat.id));
      });
      setSelectedCategoryIds(uncategorizedIds);
    } else {
      setSelectedCategoryIds([]);
    }

    await getTransactionsApi({
      accountCategories: bool ? uncategorizedIds : [],
      page: 1,
      loading: true,
    });
  };

  const handleAddedCategory = async () => {
    try {
      const accountsRes = await getAccounts();
      if (accountsRes) {
        setAllExpenseCategories(
          transformAccounts(
            accountsRes,
            'CATEGORY_PHYSICAL_ACCOUNT',
            'expense',
          ),
        );
        setAllAccounts(transformAccounts(accountsRes, 'PHYSICAL_ACCOUNT'));
        setAllModalAccounts(transformAccounts(accountsRes, 'allAccounts'));
        setAccounts(transformAccounts(accountsRes, 'PHYSICAL_ACCOUNT'));
        setAllBalances(accountsRes);
        setAccountCategories(
          transformAccounts(accountsRes, 'CATEGORY_PHYSICAL_ACCOUNT'),
        );
        setAllCategories(
          transformAccounts(accountsRes, 'CATEGORY_PHYSICAL_ACCOUNT'),
        );
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const onUnreviewedChangeInTable = (review) => {
    let tempStatus = [...selectedTransStatus];
    if (review) {
      tempStatus = [...tempStatus, review];
    } else {
      tempStatus = tempStatus.filter((s) => s !== 'review');
    }
    setSelectedTransStatus(tempStatus);

    getTransactionsApi({
      page: 1,
      status: tempStatus,
      loading: true,
    });
  };

  const { page, totalRecords } = transactions;

  return (
    <>
      <CategoryModal
        isOpen={categoryModalOpen}
        accountTypes={allAccountTypes}
        setIsOpen={setCategoryModalOpen}
        allCategoriesIncome={accountCategories}
        allCategoriesExpense={allExpenseCategories}
        allCategories={allAccounts}
        reloadTransaction={() => getTransactionsApi({ scrollToTop: false })}
        accountModalOpen={accountModalOpen}
        setAccountModalOpen={setAccountModalOpen}
        newAddedCategory={newAddedCategory}
        setNewAddedCategory={setNewAddedCategory}
        handlePopupLogic={handlePopupLogic}
      />
      <ErrorsModal
        isOpen={errorsModalOpen}
        setIsOpen={setErrorsModalOpen}
        numberOfDuplicates={numberOfDuplicates}
        numberOfMatching={numberOfMatching}
        numberOfUndepositMatching={numberOfUndepositMatching}
        numberOfInvoicesMatching={numberOfInvoicesMatching}
        numberOfBillsMatching={numberOfBillsMatching}
      />
      <StaffModal
        isOpen={staffModal}
        setIsOpen={setStaffModal}
        transaction={selectedRow}
      />
      <ReceiptModal
        isOpen={receiptModalOpen}
        setIsOpen={setReceiptModalOpen}
        receipt={activeReceipt}
      />
      <AccountsModal
        isOpen={accountModalOpen}
        setIsOpen={setAccountModalOpen}
        accountTypes={accountTypes}
        accountSubTypes={accountSubTypes}
        loadData={handleAddedCategory}
        mainType={mainType}
        taxes={taxes}
        setNewAddedCategory={setNewAddedCategory}
        loadTaxes={loadTaxes}
        accounts={allModalAccounts}
      />
      <DeleteTransactionModal
        isOpen={deleteTransactionModal}
        setIsOpen={setDeleteTransactionModal}
        handleDeleteTransaction={deleteTransactions}
        parentSplit={deleteModalParent}
        setTransactionPanelOpen={setRowDetailsOpen}
        setSelectedRow={setSelectedRow}
        failedEditCount={failedEditCount}
        failedVendorEditCount={failedVendorEditCount}
        failedCustomerEditCount={failedCustomerEditCount}
        deleteModalLoading={deleteModalLoading}
        setDeleteModalLoading={setDeleteModalLoading}
      />
      <InstancesModal
        isOpen={billModalOpen}
        setIsOpen={setBillModalOpen}
        instances={bill?.instances}
        billId={billId}
        transaction={billTransaction}
        reloadData={() => loadBillById()}
        handleSplitTransaction={handleInstancesModalSplitTransactions}
        setBillInstanceModalOpen={setBillInstanceModalOpen}
        handleOpenInstaceModal={handleOpenInstaceModal}
      />
      <BillInstanceModal
        isOpen={billInstanceModalOpen}
        setIsOpen={setBillInstanceModalOpen}
        instance={billActiveInstance}
        setInstance={setBillActiveInstance}
        reloadData={loadBillById}
        setBillModalOpen={setBillModalOpen}
      />
      <AddTransactionModal
        isOpen={addTransactionModalOpen}
        setIsOpen={setAddTransactionModalOpen}
        allCategories={allCategories}
        accounts={accounts}
        allAccounts={allAccounts}
        getTransactionsApi={getTransactionsApi}
        vendors={vendors || []}
        setVendors={setVendors}
        transType={transType}
        setTransType={setTransType}
        currencies={currencies}
        team={team}
        customers={customers}
        setCustomers={setCustomers}
        allExpenseCategories={allExpenseCategories}
        projects={projects}
      />
      <CreateRuleModal
        open={ruleModalOpen}
        setOpen={setRuleModalOpen}
        category={popupCategory}
        transaction={popupTransaction}
        reloadTransactions={getTransactionsApi}
      />
      <AddTaskModal
        modalOpen={taskModalOpen}
        setModalOpen={setTaskModalOpen}
        transactions
        selectedOption={selectedTaskOption}
        setSelectedOption={setSelectedTaskOption}
        selectedTransaction={selectedRow}
        projects={projects}
      />
      {popupOpen && (
        <CreateRulePopup
          open={popupOpen}
          setOpen={setPopupOpen}
          category={popupCategory}
          transaction={popupTransaction}
          openModal={setRuleModalOpen}
        />
      )}
      <EditTransaction
        id="feedback-modal"
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        selectedRow={selectedRow}
        reloadTransaction={getTransactionsApi}
        setSelectedRow={setSelectedRow}
      />
      <AppLayout
        ref={pageRef}
        pageId="transactionsPageContainer"
        mainClass="relative"
      >
        {accessType && (
          <main className="relative grow">
            {/* Content */}
            <div className="h-full flex flex-col px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
              {checkPermission(
                PermissionKeys.Manage_Bank_Account,
                accessType,
                permissions,
              ) && <BankWarning teamProp={team} />}
              {/* {team?.id&&team?.stripeAccountId && accessType !== VIEWER&&<StripeWarning teamProp={team} />} */}

              {(areFiltersApplied() ||
                transactions?.transactions?.length > 0 ||
                isLoading ||
                transactionsExist) && (
                <>
                  {/* Page header */}
                  <div className="flex justify-between flex-wrap items-center mb-4 md:mb-2 gap-2">
                    {/* Left: Title */}
                    <h1 className="text-3xl md:text-4xl text-black opacity-60  font-medium">
                      {billId ? bill?.vendor?.name : 'Transactions'}
                    </h1>

                    {/* Errors Messages Box  */}
                    {transactionWarningLoaded &&
                      getIfMoreThan1Warnings() > 0 && (
                        <div className="h-11 w-[400px] max-w-full  border border-blue-500 p-3 bg-blue-100 rounded-[5px] flex justify-between items-center gap-2">
                          <div className="flex gap-2 items-center">
                            {/* Warning Icon  */}
                            <svg
                              width="20"
                              height="19"
                              viewBox="0 0 20 19"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.99935 6.40332V9.73665M9.99935 13.07H10.0077M18.3327 9.73665C18.3327 14.339 14.6017 18.07 9.99935 18.07C5.39698 18.07 1.66602 14.339 1.66602 9.73665C1.66602 5.13428 5.39698 1.40332 9.99935 1.40332C14.6017 1.40332 18.3327 5.13428 18.3327 9.73665Z"
                                stroke="#3b82f6"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            <p className="text-sm font-medium text-blue-500">
                              {getIfMoreThan1Warnings() > 1
                                ? `${parseInt(numberOfDuplicates || 0) + parseInt(numberOfMatching || 0) + parseInt(numberOfUndepositMatching || 0) + parseInt(numberOfInvoicesMatching || 0) + parseInt(numberOfBillsMatching || 0)} Suggestions Need Your Review`
                                : numberOfDuplicates > 0
                                  ? `${numberOfDuplicates} Possible Duplicate Transactions Found`
                                  : numberOfMatching > 0
                                    ? `${numberOfMatching} Possible Matching Transactions Found`
                                    : numberOfUndepositMatching > 0
                                      ? `${numberOfUndepositMatching} Possible Undeposited Transactions Found`
                                      : numberOfInvoicesMatching > 0
                                        ? `${numberOfInvoicesMatching} Possible Invoice Matching Transactions Found`
                                        : numberOfBillsMatching > 0
                                          ? `${numberOfBillsMatching} Possible Bill Matching Transactions Found`
                                          : ''}
                            </p>
                          </div>
                          <button
                            type="button"
                            className="text-blue-500 text-base hover:underline font-semibold"
                            onClick={() => {
                              if (getIfMoreThan1Warnings() > 1) {
                                setErrorsModalOpen(true);
                              } else if (numberOfDuplicates > 0) {
                                navigate('/suggestions/', {
                                  state: { activeTab: 'Duplicate' },
                                });
                              } else if (numberOfMatching > 0) {
                                navigate('/suggestions/', {
                                  state: { activeTab: 'Matching' },
                                });
                              } else if (numberOfUndepositMatching > 0) {
                                navigate('/suggestions/', {
                                  state: { activeTab: 'Undeposited Transfer' },
                                });
                              } else if (numberOfInvoicesMatching > 0) {
                                navigate('/suggestions/', {
                                  state: { activeTab: 'Invoice Matching' },
                                });
                              } else if (numberOfBillsMatching > 0) {
                                navigate('/suggestions/', {
                                  state: { activeTab: 'Bill Matching' },
                                });
                              }
                            }}
                          >
                            {getIfMoreThan1Warnings() > 1 ? 'View All' : 'View'}
                          </button>
                        </div>
                      )}

                    {/* Right: Actions */}
                    <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-4">
                      {/* Delete button */}
                      <DeleteButton selectedItems={selectedItems} />

                      {/* Search form */}
                      <div className="hidden md:block">
                        <SearchForm
                          searchVal={searchVal}
                          setSearchVal={onSearch}
                          placeholder="Search"
                          transaction
                        />
                      </div>
                      {checkPermission(
                        PermissionKeys.MANAGE_TRANSACTIONS,
                        accessType,
                        permissions,
                      ) && (
                        <Tooltip
                          tooltipShow={addTransactionBtnDisabled}
                          contentClassName="border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative"
                          content="Please allow data to finish loading"
                          position="bottom"
                        >
                          <AddTransactionButton
                            transType={transType}
                            setTransType={setTransType}
                            accessType={accessType}
                            handleClick={(val) => {
                              setTransType(val);
                              setAddTransactionModalOpen(true);
                            }}
                            disabled={addTransactionBtnDisabled}
                          />
                        </Tooltip>
                      )}
                    </div>
                  </div>

                  {/* Filters */}
                  <div className="w-full mb-2 mt-12 flex sm:flex-row flex-col justify-between items-center gap-4 sm:gap-7">
                    <div className="flex-1 flex gap-4 flex-wrap">
                      <div className="flex-1">
                        <CategoriesFilter
                          allCategories={allAccounts}
                          selectedCategoryId={selectedAccount}
                          setSelectedCategoryId={setSelectedAccount}
                          getTransactionsApi={getTransactionsApi}
                          apiFilter={transactions?.filters?.accounts}
                          isReset={isReset}
                          type="account"
                        />
                      </div>
                      <div className="flex-1">
                        <CategoriesFilter
                          allCategories={accountCategories}
                          selectedCategoryId={selectedCategoryIds}
                          setSelectedCategoryId={setSelectedCategoryIds}
                          getTransactionsApi={getTransactionsApi}
                          apiFilter={transactions?.filters?.accountCategories}
                          isReset={isReset}
                          setLocalSelected={setCategoryFilterSelected}
                          localSelected={categoryFilterSelected}
                        />
                      </div>
                      <div className="flex-1">
                        <DateFilter
                          startDateFilter={startDateFilter}
                          endDateFilter={endDateFilter}
                          getTransactionsApi={getTransactionsApi}
                          setStartDateFilter={setStartDateFilter}
                          setEndDateFilter={setEndDateFilter}
                          onDateChange={onDateChange}
                          apiFilter={transactions?.filters || {}}
                          align="left"
                        />
                      </div>
                    </div>
                    <div className="hidden sm:block h-10 w-[1px] bg-[#D0D5DD80]" />
                    <div className="flex gap-4 ml-auto min-w-max ">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setAllFiltersShow((prev) => !prev);
                        }}
                        className={`btn h-10 py-0 px-4 font-normal text-sm leading-6 text-indigo-500 border ${
                          allFiltersShow ? 'bg-[#E486421A]' : ''
                        }  border-indigo-500 hover:border-indigo-600 hover:text-indigo-600 text-indigo-500`}
                        type="button"
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="mr-1"
                        >
                          <path
                            d="M2.5 6.55801H17.5C17.8969 6.55801 18.225 6.2299 18.225 5.83301C18.225 5.43611 17.8969 5.10801 17.5 5.10801H2.5C2.1031 5.10801 1.775 5.43611 1.775 5.83301C1.775 6.2299 2.1031 6.55801 2.5 6.55801Z"
                            fill="#E48642"
                            stroke="#E48642"
                            strokeWidth="0.2"
                          />
                          <path
                            d="M5 10.725H15C15.3969 10.725 15.725 10.3969 15.725 10C15.725 9.6031 15.3969 9.275 15 9.275H5C4.6031 9.275 4.275 9.6031 4.275 10C4.275 10.3969 4.6031 10.725 5 10.725Z"
                            fill="#E48642"
                            stroke="#E48642"
                            strokeWidth="0.2"
                          />
                          <path
                            d="M8.33301 14.892H11.6663C12.0632 14.892 12.3913 14.5639 12.3913 14.167C12.3913 13.7701 12.0632 13.442 11.6663 13.442H8.33301C7.93611 13.442 7.60801 13.7701 7.60801 14.167C7.60801 14.5639 7.93611 14.892 8.33301 14.892Z"
                            fill="#E48642"
                            stroke="#E48642"
                            strokeWidth="0.2"
                          />
                        </svg>
                        All Filter
                      </button>
                      <button
                        onClick={handleResetFilters}
                        className="btn h-10 py-0 px-4 font-normal text-sm leading-6 border border-red-400 text-red-400 bg-[#FF4B4B05]"
                        type="button"
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="mr-1 f"
                        >
                          <path
                            d="M12.6683 0.535107C12.2503 0.726513 12.0472 1.18354 12.1917 1.61323L12.2542 1.79683L12.1683 1.74214C10.8519 0.902294 9.49642 0.49995 8.00033 0.49995C5.41439 0.49995 3.06283 1.78511 1.65267 3.9687C1.21127 4.6562 0.789391 5.71089 0.61361 6.57808C0.492516 7.17573 0.465172 8.41792 0.555016 9.05464C0.922203 11.582 2.47689 13.707 4.76595 14.7929C6.80502 15.7656 9.1722 15.7734 11.1956 14.8164C11.7386 14.5585 12.016 14.3906 12.4886 14.0429C14.0785 12.871 15.1527 11.0703 15.4417 9.0898C15.5238 8.52339 15.5199 7.80073 15.4378 7.63667C15.1449 7.05464 14.3519 6.98042 13.9769 7.49605C13.848 7.67573 13.8441 7.69526 13.7855 8.42964C13.6488 10.1367 12.8285 11.625 11.4574 12.6601C11.0589 12.957 10.2503 13.375 9.77377 13.5273C8.58627 13.9023 7.40267 13.9023 6.22689 13.5195C3.03939 12.4882 1.36752 8.9687 2.59798 5.87495C3.19173 4.37495 4.38314 3.18354 5.87142 2.59761C7.17611 2.08198 8.57455 2.05073 9.87533 2.49995C10.223 2.62104 11.0081 2.99214 11.0081 3.03511C11.0081 3.05073 10.9456 3.0937 10.8714 3.13276C10.7972 3.17183 10.6878 3.27729 10.6292 3.36323C10.5316 3.50386 10.5199 3.55464 10.5199 3.8398C10.5199 4.12886 10.5316 4.17573 10.6331 4.32026C10.7035 4.42183 10.8167 4.51948 10.9417 4.58198L11.141 4.67964H12.598C14.1878 4.67964 14.223 4.67573 14.4613 4.45308C14.5238 4.39448 14.6058 4.2773 14.6449 4.19136C14.7816 3.89448 14.7542 3.74605 14.2699 2.30073C14.0238 1.55854 13.7777 0.890575 13.7269 0.81245C13.5238 0.507763 13.0199 0.37495 12.6683 0.535107Z"
                            fill="#FF4B4B"
                          />
                        </svg>
                        Reset
                      </button>
                    </div>
                  </div>
                  <div
                    className={`${allFiltersShow ? 'flex' : 'hidden'} flex-wrap gap-4 mt-5 sm:pr-[271.5px]`}
                  >
                    <BasicCheckboxDropdown
                      selectedOptions={selectedAccountTypes}
                      setSelectedOptions={setSelectedAccountTypes}
                      options={ACCOUNT_TYPES}
                      apiFunc={getTransactionsApi}
                      onApply={(values) => {
                        getTransactionsApi({
                          page: 1,
                          accountTypes: values,
                          loading: true,
                        });
                      }}
                      onClear={() => {
                        setSelectedAccountTypes([]);
                        getTransactionsApi({
                          page: 1,
                          accountTypes: [],
                          loading: true,
                        });
                      }}
                      label="Account Type"
                      type="accountTypes"
                      apiFilter={transactions?.filters?.accountTypes}
                    />
                    <BasicCheckboxDropdown
                      selectedOptions={selectedTransTypes}
                      setSelectedOptions={setSelectedTransTypes}
                      options={TRANSACTION_TYPES}
                      apiFunc={getTransactionsApi}
                      onApply={(values) => {
                        getTransactionsApi({
                          page: 1,
                          transactionTypes: values,
                          loading: true,
                        });
                      }}
                      onClear={() => {
                        setSelectedTransTypes([]);
                        getTransactionsApi({
                          page: 1,
                          transactionTypes: [],
                          loading: true,
                        });
                      }}
                      type="transactionTypes"
                      label="Transaction Type"
                      apiFilter={transactions?.filters?.transactionTypes}
                    />
                    <BasicCheckboxDropdown
                      selectedOptions={selectedTransStatus}
                      setSelectedOptions={setSelectedTransStatus}
                      options={TRANSACTION_STATUS}
                      apiFunc={getTransactionsApi}
                      onApply={(values) => {
                        getTransactionsApi({
                          page: 1,
                          status: values,
                          loading: true,
                        });
                      }}
                      onClear={() => {
                        setSelectedTransStatus([]);
                        getTransactionsApi({
                          page: 1,
                          status: [],
                          loading: true,
                        });
                      }}
                      label="Status"
                      type="status"
                      apiFilter={transactions?.filters?.status}
                    />
                  </div>
                  <div
                    className={`${allFiltersShow ? 'flex' : 'hidden'} flex flex-wrap gap-4 mt-5 sm:pr-[271.5px]`}
                  >
                    <BasicCheckboxDropdown
                      selectedOptions={selectedTags}
                      setSelectedOptions={setSelectedTags}
                      apiFunc={getTransactionsApi}
                      options={
                        tags?.map((t) => ({
                          label: t?.name,
                          value: t?.id,
                        })) || []
                      }
                      onApply={(values) => {
                        getTransactionsApi({
                          page: 1,
                          tags: values,
                          loading: true,
                        });
                      }}
                      onClear={() => {
                        setSelectedTags([]);
                        getTransactionsApi({
                          page: 1,
                          tags: [],
                          loading: true,
                        });
                      }}
                      label="Tags"
                      type="tags"
                      apiFilter={transactions?.filters?.tags}
                    />
                    <BasicCheckboxDropdown
                      selectedOptions={selectedVendors}
                      setSelectedOptions={setSelectedVendors}
                      apiFunc={getTransactionsApi}
                      options={
                        vendors?.map((t) => ({
                          label: t?.name,
                          value: t?.id,
                        })) || []
                      }
                      onApply={(values) => {
                        getTransactionsApi({
                          page: 1,
                          vendors: values,
                          loading: true,
                        });
                      }}
                      onClear={() => {
                        setSelectedVendors([]);
                        getTransactionsApi({
                          page: 1,
                          vendors: [],
                          loading: true,
                        });
                      }}
                      label="Vendors"
                      type="vendors"
                      apiFilter={transactions?.filters?.vendors}
                      search
                      transactionsPage
                    />
                    <BasicCheckboxDropdown
                      selectedOptions={selectedCustomers}
                      setSelectedOptions={setSelectedCustomers}
                      apiFunc={getTransactionsApi}
                      options={
                        customers?.map((t) => ({
                          label: t?.customer,
                          value: t?.id,
                        })) || []
                      }
                      onApply={(values) => {
                        getTransactionsApi({
                          page: 1,
                          customers: values,
                          loading: true,
                        });
                      }}
                      onClear={() => {
                        setSelectedCustomers([]);
                        getTransactionsApi({
                          page: 1,
                          customers: [],
                          loading: true,
                        });
                      }}
                      label="Customers"
                      type="customers"
                      apiFilter={transactions?.filters?.customers}
                      search
                      transactionsPage
                    />
                  </div>
                  {/* <div className="xl:w-fit w-full flex items-center gap-2 xl:ml-auto flex-wrap">
                  {selectedTransactions.length > 0 && (
                    <div className="xl:ml-auto flex items-center gap-2 flex-wrap">
                      <span className="text-sm italic mr-2 whitespace-nowrap">
                        {selectedTransactions.length} items selected
                      </span>
                      <ItemsSelectedDropdown
                        handleHideSelected={handleHideSelected}
                        handleCategorySelected={handleCategorySelected}
                        allCategories={allCategories}
                        tags={tags}
                        handleTagSelected={handleTagSelected}
                        handleMarkReviewedSelected={handleMarkReviewedSelected}
                      />
                    </div>
                  )}
                </div> */}

                  {/* Table */}
                  {isLoading ? (
                    <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
                      <svg
                        width="29"
                        height="29"
                        viewBox="0 0 29 29"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="animate-spin w-10 h-10 fill-current shrink-0"
                      >
                        <path
                          d="M14.5 2.41699V7.25033"
                          stroke="#E48642"
                          strokeWidth="2.55882"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M14.5 21.749V26.5824"
                          stroke="#E48642"
                          strokeWidth="2.55882"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M5.95703 5.95703L9.37662 9.37662"
                          stroke="#E48642"
                          strokeWidth="1.58955"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M19.623 19.6211L23.0426 23.0407"
                          stroke="#E48642"
                          strokeWidth="1.58955"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M2.41699 14.5H7.25033"
                          stroke="#E48642"
                          strokeWidth="1.58955"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M21.749 14.5H26.5824"
                          stroke="#E48642"
                          strokeWidth="1.58955"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M5.95703 23.0407L9.37662 19.6211"
                          stroke="#E48642"
                          strokeWidth="1.58955"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M19.623 9.37662L23.0426 5.95703"
                          stroke="#E48642"
                          strokeWidth="1.58955"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                        Fetching data, please wait...
                      </p>
                    </div>
                  ) : (
                    <>
                      {/* <div className="flex flex-col gap-10">
                        {numberOfDuplicates > 0 && (
                          <div className="">
                            <MatchingTransactionWarning
                              type="Duplicate"
                              number={numberOfDuplicates}
                            />
                          </div>
                        )}
                        {numberOfMatching > 0 && (
                          <div className="">
                            <MatchingTransactionWarning
                              type="Matching"
                              number={numberOfMatching}
                            />
                          </div>
                        )}
                        {numberOfUndepositMatching > 0 && (
                          <div className="">
                            <MatchingTransactionWarning
                              type="Undeposited Transfer"
                              number={numberOfUndepositMatching}
                            />
                          </div>
                        )}
                      </div> */}
                      {billId && <p>Please Click Transaction to Assign.</p>}
                      <TransactionsTable
                        selectedItems={handleSelectedItems}
                        getAccount={getAccount}
                        transactions={transactionsGroupBy}
                        setSelectedRow={setSelectedRow}
                        rowDetailsOpen={rowDetailsOpen}
                        setRowDetailsOpen={setRowDetailsOpen}
                        selectedRow={selectedRow}
                        allCategories={accountCategories}
                        allAccounts={allAccounts}
                        allExpenseCategories={allExpenseCategories}
                        reloadTransaction={getTransactionsApi}
                        page={page}
                        handleSortBy={handleSortBy}
                        orderBy={orderByTable}
                        orderDirection={orderDirectionTable}
                        selectedTransactions={selectedTransactions}
                        setSelectedTransactions={setSelectedTransactions}
                        handleSelectAll={handleSelectAll}
                        handleDeSelectAll={handleDeSelectAll}
                        handleTransactionCheckbox={handleTransactionCheckbox}
                        selectAll={selectAll}
                        setSelectAll={setSelectAll}
                        accessType={accessType}
                        billId={billId}
                        onRowClickForBill={onRowClickForBill}
                        // Selected Items dropdown Data
                        tags={tags}
                        handleHideSelected={handleHideSelected}
                        handleCategorySelected={handleCategorySelected}
                        handleTagSelected={handleTagSelected}
                        handleMarkReviewedSelected={handleMarkReviewedSelected}
                        currentTab={currentTransDetailTab}
                        setCurrentTab={setCurrentTransDetailTab}
                        deleteTransactions={deleteTransactions}
                        setDeleteTransactionModal={setDeleteTransactionModal}
                        setFromDropdown={setFromDropdown}
                        sortByAmount={sortByAmount}
                        setSortByAmount={setSortByAmount}
                        sortByName={sortByName}
                        setSortByName={setSortByName}
                        selectedBulkCategory={selectedBulkCategory}
                        team={team}
                        currencies={currencies}
                        categoryLoading={categoryLoading}
                        setCategoryLoading={setCategoryLoading}
                        vendors={vendors}
                        setVendors={setVendors}
                        handleVendorSelected={handleVendorSelected}
                        selectedBulkTag={selectedBulkTag}
                        setSelectedBulkTag={setSelectedBulkTag}
                        selectedBulkVendor={selectedBulkVendor}
                        setSelectedBulkVendor={setSelectedBulkVendor}
                        setSelectedBulkCategory={setSelectedBulkCategory}
                        handleBulkApply={handleBulkApply}
                        isApplying={isApplying}
                        onShowUncategorized={onShowUncategorized}
                        showOnlyUncategorized={showOnlyUncategorized}
                        handlePopupLogic={handlePopupLogic}
                        invoices={invoices}
                        assignTransactionToInvoice={assignTransactionToInvoice}
                        currentDropdownId={currentDropdownId}
                        setCurrentDropdownId={setCurrentDropdownId}
                        allInvoices={allInvoices}
                        setAccountModalOpen={setAccountModalOpen}
                        handleAddedCategory={handleAddedCategory}
                        accountModalOpen={accountModalOpen}
                        setMainType={setMainType}
                        allBills={allBills}
                        bills={bills}
                        assignTransactionToBillApi={assignTransactionToBillApi}
                        selectedTransStatus={selectedTransStatus}
                        onUnreviewedChangeInTable={onUnreviewedChangeInTable}
                        customers={customers}
                        setCustomers={setCustomers}
                        selectedBulkCustomer={selectedBulkCustomer}
                        setSelectedBulkCustomer={setSelectedBulkCustomer}
                        setCategoryModalOpen={setCategoryModalOpen}
                      />
                    </>
                  )}
                  {!isLoading && selectedRow?.account?.name && (
                    <TransactionDetails
                      transactionPanelOpen={rowDetailsOpen}
                      setTransactionPanelOpen={setRowDetailsOpen}
                      transaction={selectedRow}
                      setTransaction={setSelectedRow}
                      reloadTransaction={getTransactionsApi}
                      modalOpen={modalOpen}
                      setModalOpen={setModalOpen}
                      tags={tags}
                      setTags={setTags}
                      allCategories={accountCategories}
                      setAllCategories={setAccountCategories}
                      setAllExpenseCategories={setAllExpenseCategories}
                      vendors={vendors || []}
                      setVendors={setVendors}
                      accessType={accessType}
                      setSplitTransactionModalOpen={
                        setSplitTransactionModalOpen
                      }
                      handleSplitModalSelectInstance={
                        handleSplitModalSelectInstance
                      }
                      receiptModalOpen={receiptModalOpen}
                      setReceiptModalOpen={setReceiptModalOpen}
                      setActiveReceipt={setActiveReceipt}
                      currentTab={currentTransDetailTab}
                      setCurrentTab={setCurrentTransDetailTab}
                      accounts={accounts}
                      setFromDropdown={setFromDropdown}
                      fromDropdown={fromDropdown}
                      findSplitParent={findSplitParent}
                      allExpenseCategories={allExpenseCategories}
                      setStaffModal={setStaffModal}
                      staffModal={staffModal}
                      localUser={localUser}
                      customers={customers}
                      setCustomers={setCustomers}
                      ruleModalOpen={ruleModalOpen}
                      setRuleModalOpen={setRuleModalOpen}
                      setPopupOpen={setPopupOpen}
                      setPopupCategory={setPopupCategory}
                      setPopupTransaction={setPopupTransaction}
                      handlePopupLogic={handlePopupLogic}
                      taskModalOpen={taskModalOpen}
                      setTaskModalOpen={setTaskModalOpen}
                      setSelectedTaskOption={setSelectedTaskOption}
                      invoices={invoices}
                      assignTransactionToInvoice={assignTransactionToInvoice}
                      currencies={currencies}
                      removeTransactionFromInvoice={
                        removeTransactionFromInvoice
                      }
                      allInvoices={allInvoices}
                      allBills={allBills}
                      bills={bills}
                      assignTransactionToBillApi={assignTransactionToBillApi}
                      removeTransactionFromBill={removeTransactionFromBill}
                      projects={projects}
                      team={team}
                      taxes={taxes}
                      loadTaxes={loadTaxes}
                      allModalAccounts={allModalAccounts}
                      setAllModalAccounts={setAllModalAccounts}
                    />
                  )}
                  {/* Pagination */}
                  <div className="mt-8">
                    {!isLoading && page >= 0 && (
                      <PaginationClassic
                        pagination={{ limit: localLimit, page, totalRecords }}
                        onPageChange={(v) =>
                          getTransactionsApi({ ...v, loading: true })
                        }
                        setLimit={setLocalLimit}
                      />
                    )}
                  </div>
                </>
              )}
              {!areFiltersApplied() &&
                transactions?.transactions?.length < 1 &&
                !isLoading &&
                !transactionsExist && (
                  <div className="overflow-x-auto sm:flex w-full px-7 py-5 flex-col gap-2 rounded-xl bg-white">
                    <div className="sm:flex pt-4 pb-8 flex-col justify-center items-center gap-5 self-stretch">
                      <div className="sm:flex flex-col justify-center items-center gap-4">
                        <h2 className="text-indigo-500 text-center text-[2.25rem] font-medium leading-[3rem]">
                          Transactions
                        </h2>
                        <p className="text-slate-600 text-[1.125rem] font-normal leading-[1.125rem]">
                          Make better business decisions by accurately capturing
                          your transactions
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col sm:flex-row items-start gap-4 self-stretch">
                      <div className="sm:flex py-7 px-2 xl:px-8 flex-col justify-center items-center gap-6 flex-grow flex-shrink-0 basis-0 rounded-xl border border-[#D0D5DD] bg-white">
                        <img
                          src={emptyTransactionImg1}
                          alt=""
                          className="h-[8rem] w-auto flex items-center justify-center max-w-full mx-auto"
                        />
                        <p className="text-center text-[#101828] h-[2.375rem] text-[1.25rem] font-semibold leading-[2.375rem] flex flex-col justify-center self-stretch ">
                          Import Automatically
                        </p>
                        <p className="self-stretch text-slate-600 text-center text-[1rem] leading-6 font-normal">
                          Sync transactions from your bank in real-time. No more
                          manual data entry!
                        </p>
                        <button
                          className="flex mt-auto h-11 py-2.5 px-3.5 justify-center items-center gap-2 rounded-[5px] border border-indigo-500 shadow-sm mx-auto"
                          onClick={() =>
                            navigate(`/accounts`, {
                              state: { choiceModalOpen: true },
                            })
                          }
                          type="button"
                        >
                          Connect Bank
                        </button>
                      </div>
                      <div className="sm:flex py-7 px-2 xl:px-8 flex-col self-stretch items-center gap-6 flex-grow flex-shrink-0 basis-0 rounded-xl border border-[#D0D5DD] bg-white">
                        <img
                          src={emptyTransactionImg2}
                          alt=""
                          className="h-[8rem] w-auto flex items-center justify-center max-w-full mx-auto"
                        />
                        <p className="text-center text-[#101828] h-[2.375rem] text-[1.25rem] font-semibold leading-[2.375rem] flex flex-col justify-center self-stretch ">
                          Upload Transactions
                        </p>
                        <p className="self-stretch text-slate-600 text-center text-[1rem] leading-6 font-normal">
                          Import transactions from a bank export or Quickbooks
                          CSV file.
                        </p>
                        <button
                          className="flex mt-auto h-11 py-2.5 px-3.5 justify-center items-center gap-2 rounded-[5px] border border-indigo-500 shadow-sm mx-auto"
                          onClick={() => {
                            navigate('/import-transactions');
                          }}
                          type="button"
                        >
                          Import Transactions
                        </button>
                      </div>
                      <div className="sm:flex py-7 px-2 xl:px-8 flex-col self-stretch items-center gap-6 flex-grow flex-shrink-0 basis-0 rounded-xl border border-[#D0D5DD] bg-white">
                        <img
                          src={emptyTransactionImg3}
                          alt=""
                          className="h-[8rem] w-auto flex items-center justify-center max-w-full mx-auto"
                        />
                        <p className="text-center text-[#101828] h-[2.375rem] text-[1.25rem] font-semibold leading-[2.375rem] flex flex-col justify-center self-stretch">
                          Add Manual Transaction
                        </p>
                        <p className="self-stretch text-slate-600 text-center text-[1rem] leading-6 font-normal">
                          Manually enter income or expense transaction details.
                        </p>

                        <button
                          className="flex mt-auto h-11 py-2.5 px-3.5 justify-center items-center gap-2 rounded-[5px] border border-indigo-500 shadow-sm mx-auto"
                          onClick={() => setAddTransactionModalOpen(true)}
                          type="button"
                        >
                          Add Transaction
                        </button>
                      </div>
                    </div>
                    <div className="sm:flex pt-12 pb-5 flex-col justify-center items-center self-stretch gap-8">
                      <div className="sm:flex flex-col justify-center items-center gap-5">
                        <p className="text-[#101828] text-[1.25rem] text-center font-medium leading-[1.125rem]">
                          Not sure where to start?
                        </p>
                        <p className="text-slate-600 text-[1rem] font-normal leading-[1.125rem] text-center">
                          Connect with one of our support members to get some
                          help
                        </p>
                        <p
                          className="text-indigo-500 leading-[1.5rem] text-[0.875rem] underline cursor-pointer"
                          onClick={() => {
                            boot();
                            showNewMessage();
                          }}
                        >
                          Connect With Support
                        </p>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </main>
        )}
      </AppLayout>
    </>
  );
}

export default Transactions;
